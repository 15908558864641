body.error404 .block-hero {
  margin-bottom: 10rem;

  .slogan {
    h1, .heading-size-1,
    h2, .heading-size-2 {
      font-size: 10vmin;
      font-family: $font-subheader;
    }

    @include bp(laptop) {
      font-size: 10rem;
      margin-bottom: 14rem;
    }
  }

  .overlay-image {
    max-width: 100%;
  }
}
