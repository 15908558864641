.block-hero {
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;

  height: 100vh;

  &--half {
    max-height: 30rem;
    justify-content: flex-end;

    .slogan {
      margin: 0 !important;
      padding: 0 $page-padding;
      .slogan-big {
        line-height: 1.1;
        font-size: 10vmin !important;
        text-transform: uppercase;
      }
    }
  }

  &--full {
    max-height: 60rem;
    &.is-front-page {
      max-height: 100rem;
    }
  }

  .bg-video {
    min-width: 100%;
    height: 100%;
  }

  .background {
    position: absolute;
    z-index: 0;
    max-width: none;
    height: 100%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .entry-content,
  .slogan {
    position: relative;
    z-index: 3;
  }
  .entry-content {
    justify-self: flex-start;
    display: flex;
    flex-direction: column;

    .wp-block-button {
      margin: 6rem auto 0;
    }

    .overlay-image {
      max-width: 72rem;
      margin: 0 auto;
      padding: 0 2.5rem;
      width: 100%;
    }
  }

  .slogan {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    margin-bottom: 3rem;
    font-weight: 700;
    text-transform: capitalize;
    line-height: 0.9;
    display: flex;
    flex-direction: column;

    span {
      line-height: 0.8;
      word-wrap: break-word;
    }

    .slogan-big {
      font-size: 13.5vmin;
    }

    .slogan-small {
      font-size: 10.5vmin;
      color: $color-yellow;
    }
  }

  &.has-dark-overlay::after {
    content: '';
    background: linear-gradient(
      0deg,
      rgba($base-color, 1) 5%,
      rgba($base-color, 0.05) 100%
    );
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 2;
    width: 100%;
    height: 100%;
  }
}

@include bp(tablet) {
  .block-hero {
    &--full {
      max-height: 80rem;
      &.is-front-page {
        max-height: 110rem;
        height: 120rem;
      }
    }

    &--half {
      max-height: 40rem;

      span {
        font-size: 10rem !important;
        line-height: 1.3 !important;
      }
    }

    .slogan {
      .slogan-big {
        font-size: 15.2rem;
        line-height: 1;
      }

      .slogan-small {
        font-size: 10.2rem;
      }
    }

    .bg-video,
    .background {
      min-width: 100%;
      min-height: 100%;
    }
    
    .bg-video {
      height: auto;
    }
  }

  .single-projects .block-hero .slogan-big {
    font-size: 7rem;
  }
}

@include bp(laptop) {
  .block-hero {
    &--half {
      .slogan {
        padding-left: 9rem;
      }
    }
  }
}
@include bp(laptop-l) {
  .block-hero {
    &--half {
      .background {
        height: auto;
      }
    }
  }
}
@include bp(full) {
  .block-hero {
    &--half {
      .slogan {
        padding-left: 14rem;
      }
    }
  }
}
