.block-section {
  margin-bottom: 3.5rem;
  .entry-content {
    text-align: center;
    h2, .heading-size-2,
    h3, .heading-size-3,
    h4, .heading-size-4,
    h5 .heading-size-5 {
      font-family: $font-body;
      font-weight: bold;
      font-size: 2.4rem;
      text-transform: initial;
    }

    h3, .heading-size-3 {
      color: $color-yellow;
    }
  }

  .wp-block-button {
    margin: 3.5rem auto;
  }

  img {
    margin-left: 2.5rem;
  }
}

@include bp(tablet) {
  .block-section {
    overflow: hidden;
    position: relative;

    &::after,
    &::before {
      position: absolute;
      z-index: 2;
      content: '';
      pointer-events: none;
      background-color: transparent !important;
      height: 50%;
      left: 0;
      width: 100%;
    }
    &::before {
      top: 0;

      background: linear-gradient(
        to bottom,
        rgba($base-color, 1) 0%,
        rgba($base-color, 0) 25%
      );
    }

    &::after {
      background: linear-gradient(
        0deg,
        rgba($base-color, 1) 0%,
        rgba($base-color, 0) 80%
      );
      bottom: 0;
      top: auto;
    }

    img {
      position: absolute;
      z-index: 0;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      // min-height: 100%;
      // max-width: 0;
      // min-width: 100%;
      // width: auto;
    }

    .entry-content {
      // top: 50%;
      // position: absolute;
      // transform: translateY(-50%);
      // max-width: 58.3rem;
      // font-size: 2.1rem;
      position: relative;
      z-index: 5;
      max-width: 45%;

      h2, .heading-size-2,
      h3, .heading-size-3,
      h4, .heading-size-4,
      h5, .heading-size-5 {
        font-size: 2.7rem;
      }
      &.align-right {
        margin: 5vw 2rem 5rem auto;
        text-align: right;
        & .wp-block-button {
          display: flex;
          justify-content: flex-end;
          width: 100%;
        }
      }

      &.align-center {
        margin: 5rem auto;
        & > * {
          text-align: center;
        }
        & .wp-block-button {
          display: flex;
          justify-content: center;
          width: 100%;
        }
      }

      &.align-left {
        margin: 5rem auto 5rem 0;
        & > * {
          text-align: left;
        }

        & .wp-block-button {
          display: flex;
          justify-content: flex-start;
          width: 100%;
        }
      }
    }
  }
}

@include bp(laptop-l) {
  .block-section {
    img {
      min-height: 100%;
      max-width: none;
      width: 100%;
    }

    .entry-content {
      &.align-right {
        margin: 15vw 2rem 15vw auto;
      }

      &.align-center {
        margin: 15vw auto;
      }

      &.align-left {
        margin: 15vw auto 15vw 0;
      }
    }
  }
}

@include bp(desktop) {
  .block-section {
    .container {
      margin: 0 auto;
    }
  }
}
