.nf-form-cont .nf-form-layout {
  .nf-after-form-content,
  .nf-before-form-content {
    display: none;
  }

  .nf-field {
    position: relative;
  }

  .ninja-forms-field {
    height: auto !important;
  }

  .nf-field-element {
    input:not([type='button']),
    select,
    textarea {
      background: transparent;
      border: 0;
      border-bottom: 0.5rem solid white;
      color: #fffffe !important;
      font-family: $font-body;
      font-size: 1.8rem;
      padding-left: 0;

      &::placeholder {
        color: #fffffe;
        opacity: 0.6;
      }

      &:hover,
      &:focus {
        background: transparent;
      }

      &:focus {
        padding-left: 2rem;
        background: rgba(255, 255, 255, 0.07);
        border-color: $color-yellow;
      }
    }

    textarea {
      min-height: 25rem;
    }
  }

  .nf-field .nf-field-label label {
    color: #fffffe !important;
    font-family: $font-body;
    font-size: 1.8rem;
    font-weight: normal;
  }

  .nf-field-element select,
  .nf-field-element select:hover,
  .nf-field-element select:focus,
  .nf-field-element select:active {
    border-bottom: 0.5rem solid white;
    transition: $global-transition;
    background: url("data:image/svg+xml,<svg height='10px' width='10px' viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'><path d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/></svg>") no-repeat;
    background-color: $base-color;
    background-position: calc(100% - 0.75rem) center !important;
  }
  .nf-field-element select option {
    color: rgba(#fffffe, 0.6);
  }

  .nf-field-element select:focus {
    border-color: $color-yellow;
  }

  .nf-error.field-wrap .nf-field-element:after {
    content: none;
  }

  .nf-error .ninja-forms-field {
    border-color: $color-red !important;
  }
  .nf-pass.field-wrap .nf-field-element:after {
    display: none;
  }
  .nf-error .nf-error-msg {
    color: $color-red;
    font-size: 1.4rem;
  }

  input[type='submit'],
  input[type='button'],
  .button {
    display: block;
    transition: all 0.3s ease-in-out;
    border-radius: 4rem;
    border: 0.3rem solid;
    border-color: inherit;
    font-size: 1.6rem;
    font-weight: normal;
    font-style: normal;
    padding: 0.8rem 3rem;
    height: auto;
    color: $color-yellow;
    background: none;
    cursor: pointer;
    &:hover,
    &:focus {
      background: $color-yellow;
      border-color: $color-yellow;
    }
  }

  .file_upload-container {
    .nf-fu-progress {
      display: none;
    }

    .nf-fu-fileinput-button {
      background: 0;
      border-bottom: 0.5rem solid white;
      width: 100%;
      text-align: left;
      padding-left: 0;
      padding-top: 15px;
      padding-bottom: 15px;
      cursor: pointer;
      outline: none;
      color: rgba(#fffffe, 0.6);

      &:hover,
      &:focus {
        color: $color-white;
        border-color: $color-yellow;
      }
    }
  }
}

.lead-form {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;

  label {
    display: none;
  }

  input:not([type='button']),
  select,
  textarea {
    background: transparent;
    border: 0;
    border-bottom: 0.5rem solid white;
    color: #fffffe !important;
    font-family: $font-body;
    font-size: 1.8rem;
    padding: 1.5rem 0;
    flex-basis: 100%;
    outline: none;
    transition: $global-transition;
    margin-bottom: 2rem;

    @include bp(laptop) {
      flex-basis: 48%;
    }

    &::placeholder {
      color: #fffffe;
      opacity: 0.6;
    }

    &:hover,
    &:focus {
      background: transparent;
    }

    &:focus {
      padding-left: 2rem;
      background: rgba(255, 255, 255, 0.07);
      border-color: $color-yellow;
    }
  }

  textarea {
    min-height: 25rem;
    width: 100%;
    flex-basis: 100%;
    resize: none;
  }
  input[type='submit'],
  input[type='button'],
  .button {
    display: block;
    transition: all 0.3s ease-in-out;
    border-radius: 4rem;
    border: 0.3rem solid;
    border-color: inherit;
    font-size: 1.6rem;
    font-weight: normal;
    font-style: normal;
    padding: 0.8rem 3rem;
    height: auto;
    color: $color-yellow;
    background: none;
    cursor: pointer;
    &:hover,
    &:focus {
      background: $color-yellow;
      border-color: $color-yellow;
    }
  }

  select,
  select:hover,
  select:focus,
  select:active {
    background: $base-color;
    border-bottom: 0.5rem solid white;
    transition: $global-transition;
  }

  input[type='file'] {
    background: 0;
    border-bottom: 0.5rem solid white;
    width: 100%;
    text-align: left;
    padding-left: 0;
    padding-top: 15px;
    padding-bottom: 15px;
    cursor: pointer;
    outline: none;
    color: rgba(#fffffe, 0.6);

    &:hover,
    &:focus {
      color: $color-white;
      border-color: $color-yellow;
    }
  }
  .file-input {
    margin-bottom: 2rem;
    @include bp(laptop) {
      flex-basis: 48%;
    }
    input {
      margin-bottom: 0;
    }

    label[for='file'] {
      display: block;
      font-size: 1rem;
      opacity: 0.8;
    }
  }

  input[type='submit'] {
    border-color: $color-yellow;
    color: $color-yellow !important;

    &:hover,
    &:focus {
      color: $base-color !important;
      background: $color-yellow;
    }
  }
}