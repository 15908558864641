.page-content {

}

body {
    .site-main {
        .main-title-extra {
            font-family: $font-body;
            font-weight: 800;
            color: $color-grey-medium;
            text-align: center;
            margin: 6.8rem 0 0;
            padding: 1rem;
            font-size: 2.7rem;
        }
    }
}

@include bp(laptop) {
    body {
        .site-main {
            .main-title-extra {
                margin: 0 0 0 8rem;
                font-size: 4rem;
            }
        }
    }
}

@include bp(full) {
    body {
        .site-main {
            .main-title-extra {
                margin: 0 0 0 13rem;
            }
        }
    }
}


// Sign Type Archive page - Accordion Block
.term-extra-button {
    margin-top: 7rem;
    justify-content: center;
}
.accordion-main {
    margin: 10rem 0;
    .accordion-item {
        border-bottom: 0.1rem solid $color-white;
        &:last-child {
            border-bottom: 0;
        }
        .accordion-title {
            position: relative;
            padding: 2rem 4rem 2rem 2rem;
            font-family: $font-body;
            font-weight: normal;
            font-size: 2rem;
            margin: 0;
            cursor: pointer;
            color: $color-white;
            .icon {
                position: absolute;
                top: 0;
                right: 2rem;
                width: 1.5rem;
                height: 100%;
                transition: $global-transition;
                fill: currentColor;
            }
        }
        .accordion-description {
            display: none;
            padding: 0 2rem 2rem;
            p {
                margin: 0 0 2rem 0;
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
        &.active {
            .accordion-title {
                .icon {
                    transform: scaleY(-1);
                }
            }
        }
    }
}