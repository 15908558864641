/* ==========================================================================
   #RESET
   ========================================================================== */

/**
 * A very simple reset that sits on top of Normalize.css.
 */

* {
  margin: 0;
  &,
  &:before,
  &:after {
    box-sizing: inherit;
  }
}

/**
  * Remove trailing margins from nested lists.
  */

li > {
  ol,
  ul {
    margin-bottom: 0;
  }
}

/**
  * Remove default table spacing.
  */

table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
}

/**
  * 1. Reset Chrome and Firefox behaviour which sets a `min-width: min-content;`
  *    on fieldsets.
  */

fieldset {
  min-width: 0; /* [1] */
  border: 0;
}

hr {
  display: block;
}

a {
  color: $link-color;
  text-decoration: none;

  &:hover {
    color: $link-color--hover;
  }
}

/* ==========================================================================
   #BOX-SIZING
   ========================================================================== */

/**
 * More sensible default box-sizing:
 * css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice
 */

html {
  box-sizing: border-box;
  font-family: $font-body;
  font-size: 62.5%; /* [1] */ /* 13px */
  line-height: 1.6; /* [1] */
  min-height: 100%; /* [3] */
  overflow-y: scroll; /* [2] */
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

body {
  font-size: 16px;
  font-size: 1.6rem;
  color: $comp-color;
  overflow-x: hidden;
}

svg {
  width: 100%;
  height: 100%;
}

/* ==========================================================================
   #IMAGES
   ========================================================================== */

img {
  max-width: 100%;
  display: block;
  margin: 0;
}

/* ==========================================================================
  #WordPress Resets
  ========================================================================== */

.menu {
  list-style: none;
  margin-left: 0;
  padding-left: 0;
}

.page-content .wp-block-cover-image .wp-block-cover__inner-container,
.page-content .wp-block-cover .wp-block-cover__inner-container {
  width: 100%;
}
