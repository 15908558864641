.block-location {
  max-width: 30rem;
  margin: 5rem auto;
  a {
    color: currentColor;
    &:hover {
      color: $color-yellow;
    }
  }

  .icon {
    margin-right: 2rem;
    width: 2.6rem;
    height: 3.3rem;
    fill: $color-yellow;
  }

  .location {
    &__name {
      font-size: 5rem;
      font-family: $font-body;
      font-weight: bold;
      text-transform: initial;
    }

    &__address,
    &__phone {
      margin-bottom: 1rem;
      display: flex;
      align-items: center;
      font-size: 1.8rem;
    }
    &__address {
      .icon {
        // margin-right: 2.8rem;
      }
    }
  }
}

@include bp(laptop) {
  .block-location {
    margin: 0 auto 10rem auto;
  }
}
