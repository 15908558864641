/*
  Project: Starter
  Author: SOS Media Corp
 */

@font-face {
  font-family: 'mexcellent';
  src: url('../assets/fonts/mexcellent-webfont.woff2') format('woff2'),
    url('../assets/fonts/mexcellent-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@import "abstracts/_breakpoints.scss";
@import "abstracts/_hide.scss";
@import "abstracts/_mixins.scss";
@import "abstracts/_variables.scss";
@import "base/_globals.scss";
@import "base/_headings.scss";
@import "base/_reset.scss";
@import "layout/_404.scss";
@import "layout/_footer.scss";
@import "layout/_header.scss";
@import "layout/_layout.scss";
@import "layout/_menu.scss";
@import "layout/_page.scss";
@import "layout/_single-post.scss";
@import "layout/_single.scss";
@import "components/_block-archive.scss";
@import "components/_block-list.scss";
@import "components/_block-location.scss";
@import "components/_block-section.scss";
@import "components/_buttons.scss";
@import "components/_flexible-content.scss";
@import "components/_hero.scss";
@import "components/_imgal.scss";
@import "components/_search-modal.scss";
@import "vendors/_ninja-forms.scss";
@import "vendors/_slick-carousel.scss";
