.block-archive {
  position: relative;

  .entry-header {
    font-size: 12vmin;
    text-align: center;
    line-height: 1;
  }

  .intro {
    margin: 7rem 3.3rem 0 3.3rem;
    position: relative;

    .intro-content {
      text-align: center;
      color: black;
      padding: 4rem 2.5rem;
      background: $color-white;
      position: relative;
      z-index: 2;

      .wp-block-button {
        margin-top: 3.5rem;
      }
    }

    &::before {
      content: '';
      background: url('../assets/images/dots.png');
      width: 13.5rem;
      height: 13.5rem;
      position: absolute;
      z-index: 1;
      top: -5rem;
      left: -2rem;
    }
  }

  .term-details {
    h2, .heading-size-2,
    h3, .heading-size-3,
    h4, .heading-size-4,
    h5, .heading-size-5,
    h6 .heading-size-6 {
      font-family: $font-body;
      font-weight: 800;
      margin: 3rem 0 1rem 0;
    }
  
    p {
      margin: 0 0 2rem 0;
    }
  
    h2, .heading-size-2 {
      font-size: 4rem;
    }
    h3, .heading-size-3 {
      font-size: 3.5rem;
    }
    h4, .heading-size-4 {
      font-size: 3rem;
    }
    h5, .heading-size-5 {
      font-size: 2.5rem;
    }
    h6, .heading-size-6 {
      font-size: 2rem;
    }
    ol,
    ul {
      padding: 0 2rem;
      margin-bottom: 2rem;
      li {
        margin-bottom: 0.25rem;
      }
      li::marker {
        color: $color-yellow;
      }
    }
  }
  
  .archive-item {
    position: relative;
    overflow: hidden;
    display: block;

    .item-header {
      transition: $global-transition;
      margin-bottom: 0.5rem;
      font-size: 3.8rem;
    }

    img {
      position: relative;
      z-index: 3;
      width: 100%;
      display: block;
      opacity: 0.4;
      transition: $global-transition;
    }

    &::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 4;
      top: 50%;
      left: 50%;
      pointer-events: none;
      background: radial-gradient(
        circle,
        rgba($base-color, 0.8) 0%,
        rgba(0, 0, 0, 0) 50%
      );
    }

    &:hover,
    &:focus {
      img {
        transform: scale(1.1);
      }

      .item-header {
        color: $color-yellow;
      }
    }
  }

  .slick-slide {
    opacity: 0.4;
    transition: $global-transition;
    a {
      display: block;
      height: 100%;
    }

    &.slick-current.slick-active {
      opacity: 1;

      .item-header {
        color: $color-yellow;
      }
    }
  }

  .slick-list {
    padding: 0 3.3rem 0 3.3rem;
  }

  .archive-items.tiles {
    .archive-item {
      position: relative;

      .item-content {
        z-index: 5;
        position: absolute;
        bottom: 2rem;
        display: block;
        right: 2rem;
      }

      .excerpt {
        color: white;
        margin-top: 0;
      }
    }
  }

  // Masonary Grid

  .archive-items.masonary {
    display: grid;
    .item-header {
      position: static;
      font-size: 3rem;
      text-align: left;
      font-family: $font-body;
      font-weight: 800;
      margin-top: 0;
      text-transform: capitalize;
    }

    .item-content {
      bottom: 0;
      left: 0;
      width: 100%;
      padding: 2.5rem;
      z-index: 5;
    }

    .archive-item:hover,
    .archive-item:focus {
      .item-header {
        color: $color-white;
      }
    }
    .item-date {
      text-transform: uppercase;
      font-weight: 800;
      margin-bottom: 0.5rem;
      font-size: 1.6rem;
    }
    .excerpt {
      color: $color-white;
      margin: 0;
    }

    .wp-block-button {
      margin-top: 2rem;
    }
  }

  &--page .archive-items.tiles {
    .archive-item {
      img {
        position: absolute;
        max-width: none;
        min-height: 100%;
      }

      .excerpt {
        margin: 1rem 0 1rem auto;
        // max-width: 50%;
      }

      .item-content {
        min-height: 70vw;
        z-index: 5;
        bottom: auto;
        right: auto;
        position: relative;
        padding: 5rem 1.5rem;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        text-align: right;
        align-items: flex-end;
      }
    }
  }
  &--career .archive-items.tiles {
    .archive-item {
      .location {
        font-weight: 700;
        text-transform: uppercase;
        letter-spacing: 0.2rem;
      }

      .item-content {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        text-align: right;
        max-width: 60%;
      }

      .item-header {
        margin: 1rem 0;
      }
      .wp-block-button {
        margin-left: auto;
        // display: flex;
      }
    }
  }

  .archive-items.archive-featured {
    .archive-item {
      position: relative;
    }
    .item-header {
      z-index: 5;
      position: absolute;
      bottom: 2rem;
      right: 2rem;

      &.has-text-align-right {
        text-align: right;
      }

      &.has-text-align-center {
        text-align: center;
      }
      &.has-text-align-left {
        text-align: left;
      }
    }

    @include bp(laptop) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      img {
        height: auto;
      }
    }
  }
}

.archive-load-more {
  display: flex;
  align-items: center;
  transition: all 0.3s ease-in-out;
  border-radius: 4rem;
  border: 0.3rem solid;
  border-color: inherit;
  font-size: 1.6rem;
  font-weight: normal;
  font-style: normal;
  padding: 0.8rem 4rem;
  color: $link-color;
  background: none;
  cursor: pointer;
  outline: none;
  margin: 5rem auto 5rem auto;

  datafield {
    display: none;
  }

  .icon {
    width: 1.8rem;
    height: 1.8rem;
    margin-right: 1rem;
    transform: rotate(90deg);
  }

  &:hover,
  &:focus {
    .icon {
      animation-name: bounce-3;
      animation-timing-function: ease;
    }
  }

  @include bp(laptop-l) {
    position: absolute;
    z-index: 5;
    right: 0rem;
    bottom: 10rem;
    transform: rotate(-90deg);
    margin: 0;

    .icon {
      transform: scaleX(-1);
      transition: all 0.3s ease-in-out;
      animation-duration: 2s;
      animation-iteration-count: infinite;
    }
  }
}
@keyframes bounce-down {
  0% {
    transform: scaleX(-1);
  }
  30% {
    transform: scaleX(-1) translateX(2rem);
  }
  50% {
    transform: scaleX(-1) translateX(0);
  }
  100% {
    transform: scaleX(-1) translateX(0);
  }
}
@keyframes bounce-3 {
  0% {
    transform: scaleX(-1);
  }
  30% {
    transform: scaleX(-1) translateX(2rem);
  }
  50% {
    transform: scaleX(-1) translateX(0);
  }
  100% {
    transform: scaleX(-1) translateX(0);
  }
}

.block-archive .archive-items.archive-items--odd {
  .header-image-full {
    display: none;
  }
}

@include bp(tablet) {
  .block-archive {
    .archive-items.tiles {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }

    .archive-items.archive-items--odd {
      .archive-item:last-of-type {
        grid-column: 1 / span 2;
        height: 45rem;

        img {
          position: absolute;
          top: 50%;
          transform: translate(0, -50%);
          min-height: 100%;
        }

        img:not(.industry-image):first-of-type {
          // display: none;
        }

        .header-image-full {
          display: block;
        }
      }
    }
    &--page .archive-items.tiles {
      .archive-item {
        .item-content {
          min-height: 50vw;
        }
      }
    }
  }
}

@include bp(laptop) {
  .block-archive {
    // margin-top: 23rem;

    .archive-items.archive-items--odd {
      .archive-item:last-of-type {
        height: 63rem;
      }
    }

    .entry-content {
      display: flex;
      align-items: stretch;
    }

    .entry-header {
      //   font-size: 15.1rem;
      font-size: 8vmin;
      text-align: left;
      margin: 0 0 3rem 8rem;
      line-height: 0.9;
      align-self: center;
    }

    .entry-header,
    .intro {
      flex-basis: 50%;
    }

    .intro {
      margin: 0 0 0 7rem;

      &::before {
        left: -8rem;
        top: -4rem;
      }

      .intro-content {
        height: 100%;
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        text-align: left;
        padding: 8rem 12rem 8rem 8rem;
      }
    }

    .archive-item {
      img {
        opacity: 0.4;
        height: 100%;
        transition: $global-transition;
      }

      .item-header {
        font-size: 6rem;
      }

      &:hover,
      &:focus {
        .item-header {
          color: $color-yellow;
        }
      }
    }
    .archive-items.masonary {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }
    &--page .archive-items.tiles {
      .archive-item {
        img {
          position: static;
          min-height: none;
        }
        .item-content {
          min-height: 0;
          position: absolute;
          padding: 0;
          height: 100%;
          bottom: 2rem;
          right: 2rem;
          max-width: 80%;
        }
      }
    }

    .archive-items.tiles {
      .archive-item .excerpt {
        text-align: right;
      }
      img {
        display: block;
        height: auto;
      }

      // .archive-item:last-of-type {
      //   grid-column: 1 / span 2;
      // }
    }

    .archive-items.masonary {
      .archive-item {
        .item-content {
          position: absolute;
        }
      }
    }
  }
}

@include bp(laptop-l) {
  .block-archive {
    .entry-header {
      font-size: 15.1rem;
    }
    .archive-items.tiles {
      .archive-item .item-content {
        right: 5.5rem;
        bottom: 5.5rem;
      }
    }
    .archive-items.masonary {
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: repeat(3, 1fr);
      align-content: space-around;
      justify-content: space-between;
      height: 125rem;

      .item-content {
        max-width: 75%;
      }

      .archive-item:nth-of-type(1n) {
        grid-column: 1 / span 2;
        grid-row: 1 / span 2;
      }
      .archive-item:nth-of-type(2n) {
        grid-column: 3;
        grid-row: 1;
      }
      .archive-item:nth-of-type(3n) {
        grid-column: 4;
        grid-row: 1;
      }

      .archive-item:nth-of-type(4n) {
        grid-column: 1;
        grid-row: 3;
      }

      .archive-item:nth-of-type(5n) {
        grid-column: 2;
        grid-row: 3;
      }

      .archive-item:nth-of-type(6n) {
        grid-column: 3 / span 4;
        grid-row: 2 / span 4;
      }

      .archive-item:nth-of-type(2n),
      .archive-item:nth-of-type(3n),
      .archive-item:nth-of-type(4n),
      .archive-item:nth-of-type(5n) {
        .excerpt {
          display: none;
        }
      }
      .archive-item:nth-of-type(6n) .excerpt {
        display: block;
      }
    }
  }
}
