.block-list {
  margin-bottom: 5rem;
  @include bp(laptop) {
    margin-bottom: 15rem;
  }
  h2, .heading-size-2,
  h3, .heading-size-3 {
    line-height: 0.9;
    font-family: $font-body;
    font-weight: 800;
  }

  h2, .heading-size-3 {
    font-size: 12vmin;
  }

  h3, .heading-size-3 {
    font-size: 7.5vmin;
    color: $color-yellow;
  }

  .list {
    margin: 3rem 0;
    ul {
      padding: 0;
      counter-reset: list-item;
    }
    li {
      counter-increment: list-item;
      font-size: 3.2rem;
      font-weight: 800;
      list-style: none;
      margin-bottom: 1rem;
      opacity: 0.6;
      transition: $global-transition;

      &::before {
        content: '';
        width: 1rem;
        height: 1rem;
        background: $color-yellow;
        display: inline-block;
        vertical-align: middle;
        margin-right: 1rem;
      }

      &::after {
        content: '0' counter(list-item);
        font-size: 1.2rem;
        position: absolute;
      }

      &:hover,
      &:focus {
        opacity: 1;
      }

      &.highlight {
        opacity: 1;
      }
    }

    + .wp-block-button {
      display: flex;
      justify-content: center;
      margin: 5rem 0 7rem 0;
    }
  }
}

@include bp(tablet) {
  .block-list {
    h2, .heading-size-2 {
      font-size: 14.1rem;
    }

    h3, .heading-size-3 {
      font-size: 8.4rem;
      margin-bottom: 3rem;
    }
    p {
      font-size: 2.1rem;
      margin-bottom: 6rem;
    }

    .list li {
      font-size: 5rem;
    }

    .list.list-horizontal {
      ul {
        display: flex;
        flex-flow: row wrap;
        justify-content: center;

        li {
          text-align: left;
          margin: 0 1vmin 1vmin;
        }

        li::after {
          content: none;
        }
      }
    }
  }
}
