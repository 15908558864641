.logo-slider {
  padding: 5rem 0;

  ul {
    padding: 0;
  }

  li {
    list-style: none;
  }

  .logo-item {
    margin-bottom: 2rem;
    filter: grayscale(100%);
    transition: $global-transition;

    &:hover,
    &:focus {
      filter: grayscale(0);
    }
  }
}

.site-info {
  text-align: center;
  padding-bottom: 3rem;
  font-size: 1.4rem;
  color: $color-grey-light;

  a {
    color: $color-grey-light;

    &:hover,
    &:focus {
      color: $color-yellow;
    }
  }
}

@include bp(tablet) {
  .site-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
