.single-projects,
.single-career,
.single-post {
  h2, .heading-size-2,
  h3, .heading-size-3,
  h4, .heading-size-4,
  h5, .heading-size-5,
  h6 .heading-size-6 {
    font-family: $font-body;
    font-weight: 800;
    margin: 3rem 0 1rem 0;
  }

  p {
    margin: 0 0 2rem 0;
  }

  h2, .heading-size-2 {
    font-size: 4rem;
  }
  h3, .heading-size-3 {
    font-size: 3.5rem;
  }
  h4, .heading-size-4 {
    font-size: 3rem;
  }
  h5, .heading-size-5 {
    font-size: 2.5rem;
  }
  h6, .heading-size-6 {
    font-size: 2rem;
  }
  ol,
  ul {
    padding: 0 2rem;
    margin-bottom: 2rem;
    li {
      margin-bottom: 0.25rem;
    }
    li::marker {
      color: $color-yellow;
    }
  }

  // Hero
  .block-hero {
    .post-date-category {
      color: $color-yellow--darker;
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 1.6rem;
      font-weight: 700;
      text-transform: uppercase;
      letter-spacing: 0.2rem;
      margin-bottom: 2rem;
      .spacer {
        margin: 0 1rem;
        display: none;
      }

      @include bp(tablet) {
        flex-direction: row;
        justify-content: center;
        align-items: auto;
        .spacer {
          display: inline-block;
        }
      }
      .hero-post-categories,
      p {
        margin: 0;
      }
    }

    .hero-post-categories {
      padding: 0;

      display: flex;
      flex-flow: row wrap;

      li {
        list-style: none;

        &:not(:first-of-type):last-of-type::before {
          content: ', ';
        }
      }
    }

    .slogan {
      position: static;
      transform: none;
    }
  }
  .post-navigation,
  .post-content.container {
    max-width: 115rem;
  }

  .post-navigation {
    display: flex;
    justify-content: space-between;
    margin: 5rem auto 9rem auto;
    font-size: 1.6rem;
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 0.2rem;
    img {
      width: 100%;
    }
    a {
      display: flex;
      align-items: center;
    }

    .prev:hover,
    .prev:focus {
      .icon {
        transform: scaleX(-1) translateX(5px);
      }
    }
    .next:hover,
    .next:focus {
      .icon {
        transform: translateX(5px);
      }
    }

    .prev .icon {
      transform: scaleX(-1);
      margin-right: 1rem;
    }
    .next .icon {
      margin-left: 1rem;
    }

    .icon {
      transition: $global-transition;
      width: 1.8rem;
      height: 1.5rem;
    }
  }
}

// Single Project

.single-projects {
  .container {
    max-width: 130rem;
  }

  .project-header {
    text-transform: uppercase;
    margin-bottom: 6rem;

    .label {
      color: $color-yellow;
      font-weight: 800;
      letter-spacing: 0.2rem;
      margin-bottom: 1rem;
      display: block;
    }

    p {
      font-family: $font-subheader;
      font-size: 3.5rem;
      line-height: 1;
    }
  }

  .project-sign-type {
    padding: 0;
    li {
      list-style: none;
      font-family: $font-subheader;
      font-size: 3.5rem;
      line-height: 1;
    }
  }

  .post-navigation {
    margin: 0;
    max-width: none;
    padding: 0;
    display: flex;
    flex-direction: column;
  }

  .tile {
    position: relative;
    overflow: hidden;

    .content {
      position: absolute;
      z-index: 5;
      bottom: 2rem;
      right: 2rem;
      text-align: right;
      max-width: 50%;
    }
    .title,
    img {
      transition: $global-transition;
    }

    .title {
      margin: 0;
      font-family: $font-subheader;
    }

    img {
      opacity: 0.4;
    }

    &:hover,
    &:focus {
      img {
        transform: scale(1.1);
      }

      .title {
        color: $color-yellow;
      }
    }
  }
}

.single-career {
  .location {
    font-size: 2.7rem;
    color: $color-yellow;
    font-weight: bold;
    text-transform: uppercase;
    margin: 5rem 0 0 0;
  }
}

@include bp(tablet) {
  .single-projects {
    .project-header {
      .wp-block-columns {
        justify-content: flex-start;
        // gap: 3rem;
      }
    }
    
    .tile {
      .content {
        bottom: 5.5rem;
        right: 5.5rem;
      }
    }
    .post-navigation {
      flex-direction: row;
      a {
        flex-basis: 50%;
      }
    }
  }
}

@include bp(laptop) {
  .single-projects {
    .project-header {
      .wp-block-columns {
        .wp-block-column {
          flex: 0 1 auto !important;
          max-width: 40rem;
          gap: 0;
          &:not(:last-of-type) {
            padding-right: 3rem;
            border-right: 0.1rem solid #514f59;
          }
          &:not(:first-of-type) {
            padding-left: 3rem;
          }
        }
      }
    }
  }
}