.page-content {
  .wp-block-button .wp-block-button__link,
  .wp-block-button__link:visited {
    display: block;
    transition: all 0.3s ease-in-out;
    border-radius: 4rem;
    border: 0.3rem solid;
    border-color: inherit;
    font-size: 1.6rem;
    font-weight: normal;
    font-style: normal;
    padding: 0.8rem 4rem;
    color: $link-color;
    background: none;
  }

  .wp-block-button {
    display: inline-block;

    &.has-style-outline-black {
      border-color: black;

      .wp-block-button__link {
        color: black;
        padding: 0.8rem 5.5rem;
      }

      &:hover,
      &:focus {
        border: $color-yellow;

        .wp-block-button__link {
          color: black !important;
        }
      }
    }
  }

  .wp-block-button__link {
    &:hover,
    &:focus {
      background: $color-yellow;
      color: black;
      border-color: $color-yellow;
    }
  }
}
