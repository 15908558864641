@charset "UTF-8";
/*
  Project: Starter
  Author: SOS Media Corp
 */
@font-face {
  font-family: 'mexcellent';
  src: url("../assets/fonts/mexcellent-webfont.woff2") format("woff2"), url("../assets/fonts/mexcellent-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

/**
 * Hide only visually, but have it available for screen readers:
 * http://snook.ca/archives/html_and_css/hiding-content-for-accessibility
 */
.visually-hidden {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  white-space: nowrap !important;
  width: 1px !important;
}

/**
 * Hide visually and from screen readers.
 */
.hidden {
  display: none !important;
}

/* Fonts
   ========================================================================== */
/* Breakpoints
   ========================================================================== */
/* Colors
   ========================================================================== */
/* Colors Social
   ========================================================================== */
.has-text-color-yellow {
  color: #ffeb5c;
}

.flicker-white {
  animation: text-flicker-white 5s linear infinite;
}

.flicker-white .offset {
  animation: letter-flicker 4s linear infinite;
}

.flicker-yellow {
  animation: text-flicker-yellow 5s linear infinite;
}

@keyframes text-flicker-yellow {
  0% {
    opacity: 0.1;
    text-shadow: 0px 0px 20px #ffeb5c;
  }
  2% {
    opacity: 1;
    text-shadow: 0px 0px 20px #ffeb5c;
  }
  8% {
    opacity: 0.1;
    text-shadow: 0px 0px 20px #ffeb5c;
  }
  9% {
    opacity: 1;
    text-shadow: 0px 0px 20px #ffeb5c;
  }
  12% {
    opacity: 0.1;
    text-shadow: 0px 0px #ffeb5c;
  }
  20% {
    opacity: 1;
    text-shadow: 0px 0px 20px #ffeb5c;
  }
  25% {
    opacity: 0.3;
    text-shadow: 0px 0px 20px #ffeb5c;
  }
  30% {
    opacity: 1;
    text-shadow: 0px 0px 20px #ffeb5c;
  }
  70% {
    opacity: 0.7;
    text-shadow: 0px 0px 20px #ffeb5c;
  }
  72% {
    opacity: 0.2;
    text-shadow: 0px 0px 20px #ffeb5c;
  }
  77% {
    opacity: 0.9;
    text-shadow: 0px 0px 20px #ffeb5c;
  }
  100% {
    opacity: 0.9;
    text-shadow: 0px 0px 20px #ffeb5c;
  }
}

@keyframes text-flicker-white {
  0% {
    opacity: 0.1;
    text-shadow: 0px 0px 20px white;
  }
  2% {
    opacity: 1;
    text-shadow: 0px 0px 20px white;
  }
  8% {
    opacity: 0.1;
    text-shadow: 0px 0px 20px white;
  }
  9% {
    opacity: 1;
    text-shadow: 0px 0px 20px white;
  }
  12% {
    opacity: 0.1;
    text-shadow: 0px 0px white;
  }
  20% {
    opacity: 1;
    text-shadow: 0px 0px 20px white;
  }
  25% {
    opacity: 0.3;
    text-shadow: 0px 0px 20px white;
  }
  30% {
    opacity: 1;
    text-shadow: 0px 0px 20px white;
  }
  70% {
    opacity: 0.7;
    text-shadow: 0px 0px 20px white;
  }
  72% {
    opacity: 0.2;
    text-shadow: 0px 0px 20px white;
  }
  77% {
    opacity: 0.9;
    text-shadow: 0px 0px 20px white;
  }
  100% {
    opacity: 0.9;
    text-shadow: 0px 0px 20px white;
  }
}

@keyframes letter-flicker {
  0% {
    opacity: 0.1;
    text-shadow: 0px 0px 20px white;
  }
  2% {
    opacity: 0.1;
    text-shadow: 0px 0px 20px white;
  }
  4% {
    opacity: 1;
    text-shadow: 0px 0px 20px white;
  }
  19% {
    opacity: 1;
    text-shadow: 0px 0px 20px white;
  }
  21% {
    opacity: 0.1;
    text-shadow: 0px 0px 20px white;
  }
  23% {
    opacity: 1;
    text-shadow: 0px 0px 20px white;
  }
  80% {
    opacity: 1;
    text-shadow: 0px 0px 20px white;
  }
  83% {
    opacity: 0.4;
    text-shadow: 0px 0px 20px white;
  }
  87% {
    opacity: 1;
    text-shadow: 0px 0px 20px white;
  }
}

@keyframes neon {
  0%,
  100% {
    text-shadow: 0 0 1vw #fa1c16, 0 0 3vw #fa1c16, 0 0 10vw #fa1c16, 0 0 10vw #fa1c16, 0 0 0.4vw #fed128, 0.5vw 0.5vw 0.1vw #806914;
    color: #fed128;
  }
  50% {
    text-shadow: 0 0 0.5vw #800e0b, 0 0 1.5vw #800e0b, 0 0 5vw #800e0b, 0 0 5vw #800e0b, 0 0 0.2vw #800e0b, 0.5vw 0.5vw 0.1vw #40340a;
    color: #806914;
  }
}

@keyframes move-right {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(1rem);
  }
  100% {
    transform: translateX(0);
  }
}

.neon {
  animation: neon 2.5s linear infinite;
}

.wp-block-group h2, .wp-block-group .heading-size-2,
.wp-block-group h3, .wp-block-group .heading-size-3,
.wp-block-group h4, .wp-block-group .heading-size-4,
.wp-block-group h5, .wp-block-group .heading-size-5,
.wp-block-group h6 .heading-size-6,
.wysiwyg h2,
.wysiwyg .heading-size-2,
.wysiwyg h3,
.wysiwyg .heading-size-3,
.wysiwyg h4,
.wysiwyg .heading-size-4,
.wysiwyg h5,
.wysiwyg .heading-size-5,
.wysiwyg h6 .heading-size-6 {
  font-family: "Open Sans", sans-serif;
  font-weight: 800;
  margin: 3rem 0 1rem 0;
  color: #ffeb5c;
}

.wp-block-group p,
.wysiwyg p {
  margin: 0 0 2rem 0;
}

.wp-block-group h2, .wp-block-group .heading-size-2,
.wysiwyg h2,
.wysiwyg .heading-size-2 {
  font-size: 4rem;
}

.wp-block-group h3, .wp-block-group .heading-size-3,
.wysiwyg h3,
.wysiwyg .heading-size-3 {
  font-size: 3.5rem;
}

.wp-block-group h4, .wp-block-group .heading-size-4,
.wysiwyg h4,
.wysiwyg .heading-size-4 {
  font-size: 3rem;
}

.wp-block-group h5, .wp-block-group .heading-size-5,
.wysiwyg h5,
.wysiwyg .heading-size-5 {
  font-size: 2.5rem;
}

.wp-block-group h6, .wp-block-group .heading-size-6,
.wysiwyg h6,
.wysiwyg .heading-size-6 {
  font-size: 2rem;
}

.wp-block-group ol,
.wp-block-group ul,
.wysiwyg ol,
.wysiwyg ul {
  padding: 0 2rem;
  margin-bottom: 2rem;
}

.wp-block-group ol li,
.wp-block-group ul li,
.wysiwyg ol li,
.wysiwyg ul li {
  margin-bottom: 0.25rem;
}

.wp-block-group ol li::marker,
.wp-block-group ul li::marker,
.wysiwyg ol li::marker,
.wysiwyg ul li::marker {
  color: #ffeb5c;
}

/* ==========================================================================
   #HEADINGS
   ========================================================================== */
/**
 * Simple default styles for headings 1 through 6. Anything more opinionated
 * than simple font-size changes should likely be applied via classes (see:
 * http://csswizardry.com/2016/02/managing-typography-on-large-apps/).
 */
h1, h2, h3, h4, .heading-size-1, .heading-size-2, .heading-size-3, .heading-size-4 {
  line-height: 1.1;
  margin: 1.2rem 0;
  color: #fff;
}

h1 + p, h2 + p, h3 + p, h4 + p, .heading-size-1 + p, .heading-size-2 + p, .heading-size-3 + p, .heading-size-4 + p {
  margin-top: 2.4rem;
}

h1, h2, .heading-size-1, .heading-size-2 {
  font-family: "Open Sans", sans-serif;
  font-weight: 800;
  text-transform: uppercase;
  font-size: 4rem;
  word-break: normal;
  word-wrap: normal;
  overflow-wrap: normal;
}

@media (min-width: 768px) {
  body h1, body .heading-size-1 {
    font-size: 4rem;
  }
}

@media (min-width: 1440px) {
  body h1, body .heading-size-1 {
    font-size: 8rem;
  }
}

@media (min-width: 1600px) {
  h2, .heading-size-2 {
    font-size: 7rem;
  }
}

h3, .heading-size-3 {
  font-size: 2.7rem;
  font-weight: normal;
  font-family: "mexcellent", sans-serif;
  text-transform: uppercase;
}

@media (min-width: 1600px) {
  h3, .heading-size-3 {
    font-size: 4rem;
  }
}

h4, .heading-size-4 {
  font-size: 2rem;
  font-weight: 400;
  font-family: "mexcellent", sans-serif;
  text-transform: uppercase;
  margin-bottom: 1rem;
}

@media (min-width: 1600px) {
  h4, .heading-size-4 {
    font-size: 2.4rem;
  }
}

/* ==========================================================================
   #RESET
   ========================================================================== */
/**
 * A very simple reset that sits on top of Normalize.css.
 */
* {
  margin: 0;
}

*, *:before, *:after {
  box-sizing: inherit;
}

/**
  * Remove trailing margins from nested lists.
  */
li > ol,
li > ul {
  margin-bottom: 0;
}

/**
  * Remove default table spacing.
  */
table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
}

/**
  * 1. Reset Chrome and Firefox behaviour which sets a `min-width: min-content;`
  *    on fieldsets.
  */
fieldset {
  min-width: 0;
  /* [1] */
  border: 0;
}

hr {
  display: block;
}

a {
  color: #ffeb5c;
  text-decoration: none;
}

a:hover {
  color: #ffe843;
}

/* ==========================================================================
   #BOX-SIZING
   ========================================================================== */
/**
 * More sensible default box-sizing:
 * css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice
 */
html {
  box-sizing: border-box;
  font-family: "Open Sans", sans-serif;
  font-size: 62.5%;
  /* [1] */
  /* 13px */
  line-height: 1.6;
  /* [1] */
  min-height: 100%;
  /* [3] */
  overflow-y: scroll;
  /* [2] */
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

body {
  font-size: 16px;
  font-size: 1.6rem;
  color: #fff;
  overflow-x: hidden;
}

svg {
  width: 100%;
  height: 100%;
}

/* ==========================================================================
   #IMAGES
   ========================================================================== */
img {
  max-width: 100%;
  display: block;
  margin: 0;
}

/* ==========================================================================
  #WordPress Resets
  ========================================================================== */
.menu {
  list-style: none;
  margin-left: 0;
  padding-left: 0;
}

.page-content .wp-block-cover-image .wp-block-cover__inner-container,
.page-content .wp-block-cover .wp-block-cover__inner-container {
  width: 100%;
}

body.error404 .block-hero {
  margin-bottom: 10rem;
}

body.error404 .block-hero .slogan h1, body.error404 .block-hero .slogan .heading-size-1,
body.error404 .block-hero .slogan h2, body.error404 .block-hero .slogan .heading-size-2 {
  font-size: 10vmin;
  font-family: "mexcellent", sans-serif;
}

@media (min-width: 1024px) {
  body.error404 .block-hero .slogan {
    font-size: 10rem;
    margin-bottom: 14rem;
  }
}

body.error404 .block-hero .overlay-image {
  max-width: 100%;
}

.logo-slider {
  padding: 5rem 0;
}

.logo-slider ul {
  padding: 0;
}

.logo-slider li {
  list-style: none;
}

.logo-slider .logo-item {
  margin-bottom: 2rem;
  filter: grayscale(100%);
  transition: all 300ms ease-in-out;
}

.logo-slider .logo-item:hover, .logo-slider .logo-item:focus {
  filter: grayscale(0);
}

.site-info {
  text-align: center;
  padding-bottom: 3rem;
  font-size: 1.4rem;
  color: #a6a5af;
}

.site-info a {
  color: #a6a5af;
}

.site-info a:hover, .site-info a:focus {
  color: #ffeb5c;
}

@media (min-width: 768px) {
  .site-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.site-header {
  background: rgba(28, 27, 33, 0.7);
  display: flex;
  align-items: stretch;
  height: 6.8rem;
  position: fixed;
  z-index: 999;
  top: 0;
  width: 100%;
}

.site-header .site-logo {
  width: 8rem;
  background: #fff;
  padding: 1rem 0.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.site-header__quote, .site-header__search, .site-header__phone {
  display: inline-block;
  max-width: 8rem;
  max-height: 8rem;
}

.site-header svg {
  fill: #ffeb5c;
  padding: 2.3rem;
  width: 100%;
  height: 100%;
  display: block;
}

.site-header .menu-button, .site-header__phone, .site-header__quote {
  border-right: solid 0.1rem #2e2c34;
}

.site-header__search {
  background: transparent;
  width: auto;
  margin-right: auto;
  border: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (min-width: 1024px) {
  .site-header {
    flex-direction: column;
    height: 100%;
    max-width: 8rem;
    left: 0;
    background: linear-gradient(to bottom, #1c1b21 0%, rgba(28, 27, 33, 0) 100%);
  }
  .site-header.scroll {
    background: rgba(28, 27, 33, 0.7);
  }
  .site-header .menu-button, .site-header__phone, .site-header__quote {
    border-right: 0;
    border-bottom: solid 0.1rem #2e2c34;
  }
  .site-header__phone, .site-header__quote, .site-header__search {
    width: 8rem;
    height: 8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
  }
  .site-header__phone svg, .site-header__quote svg, .site-header__search svg {
    width: 2.5rem;
    height: 2.5rem;
    display: block;
    margin: auto;
    padding: 0;
  }
  .site-header .menu-button {
    padding: 2.5rem 0;
  }
  .site-header__search {
    cursor: pointer;
  }
}

.admin-bar .site-header {
  top: 4.6rem;
}

@media (min-width: 600px) {
  .admin-bar .site-header {
    top: 3.2rem;
  }
}

@media (min-width: 1920px) {
  .site-header {
    transition: all 300ms ease-in-out;
  }
}

/* ==========================================================================
   #CONTAINER
   ========================================================================== */
.site,
body {
  background-color: #1c1b21;
}

/**
 * Page-level constraining and wrapping elements.
 */
.wp-block-group,
.container {
  margin-right: auto;
  margin-left: auto;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  max-width: 1760px;
}

.wp-block-group.small,
.container.small {
  max-width: 130rem;
}

@media (min-width: 1024px) {
  .wp-block-group,
  .container {
    padding-left: 9%;
    padding-right: 9%;
  }
}

@media (min-width: 1920px) {
  .wp-block-group,
  .container {
    padding-left: 10rem;
    padding-right: 10rem;
  }
}

.wp-block-image img {
  height: auto;
}

/* ==========================================================================
   #Info block
   ========================================================================== */
.wp-block-columns.info-blocks .wp-block-column {
  position: relative;
  overflow: hidden;
  padding: 6rem 1.5rem;
  z-index: 1;
  text-align: right;
  margin: 0;
}

.wp-block-columns.info-blocks .wp-block-column .number {
  font-size: 5.3rem;
  display: block;
  width: 100%;
  font-family: "Open Sans", sans-serif;
  font-weight: 800;
}

.wp-block-columns.info-blocks .wp-block-column .description {
  font-size: 1.6rem;
  color: #ffeb5c;
  font-weight: 800;
  font-family: "Open Sans", sans-serif;
}

.wp-block-columns.info-blocks .wp-block-column img {
  position: absolute;
  z-index: -1;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  opacity: 0.65;
  min-height: 100%;
  max-width: none;
}

@media (min-width: 600px) {
  .wp-block-columns.info-blocks {
    flex-wrap: nowrap !important;
  }
}

@media (min-width: 1024px) {
  .wp-block-columns.info-blocks .wp-block-column {
    padding: 12rem 2.5rem;
  }
  .wp-block-columns.info-blocks .wp-block-column img {
    min-width: 100%;
  }
}

.wp-block-group .wp-block-columns .wp-block-column h3, .wp-block-group .wp-block-columns .wp-block-column .heading-size-3 {
  color: #ffeb5c;
  font-family: "Open Sans", sans-serif;
  font-size: 2.7rem;
  font-weight: bold;
}

/* ==========================================================================
   #Cover Block
========================================================================== */
.wp-block-cover {
  height: auto !important;
}

.wp-block-cover::after, .wp-block-cover::before {
  content: '';
  pointer-events: none;
  background-color: transparent !important;
  height: 50%;
}

.wp-block-cover::before {
  background: linear-gradient(to bottom, #1c1b21 0%, rgba(28, 27, 33, 0) 25%);
}

.wp-block-cover::after {
  background: linear-gradient(0deg, #1c1b21 0%, rgba(28, 27, 33, 0) 80%);
  bottom: 0;
  top: auto;
}

.wp-block-cover .wp-block-cover__inner-container {
  display: block;
  padding-top: 5rem;
  padding-bottom: 5rem;
}

@media (min-width: 1024px) {
  .wp-block-cover .wp-block-cover__inner-container {
    padding-top: 10rem;
    padding-bottom: 10rem;
  }
}

/* ==========================================================================
   # Media Text
========================================================================== */
.wp-block-media-text {
  display: block !important;
}

.wp-block-media-text .wp-block-media-text__media {
  position: relative;
}

.wp-block-media-text .wp-block-media-text__media::before, .wp-block-media-text .wp-block-media-text__media::after {
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
  bottom: 0;
  height: 100%;
}

.wp-block-media-text .wp-block-media-text__media::before {
  background: linear-gradient(to bottom, #1c1b21 5%, rgba(28, 27, 33, 0) 50%, #1c1b21 95%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}

.wp-block-media-text .wp-block-media-text__media::after {
  background: linear-gradient(to left, #1c1b21 5%, rgba(28, 27, 33, 0) 50%, #1c1b21 100%);
}

.wp-block-media-text.is-stacked-on-mobile .wp-block-media-text__content,
.wp-block-media-text .wp-block-media-text__content {
  padding: 0 1.5rem;
}

@media (min-width: 1024px) {
  .wp-block-media-text {
    display: grid !important;
  }
  .wp-block-media-text .wp-block-media-text__content {
    max-width: 87.5rem;
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
  .wp-block-media-text .wp-block-media-text__media {
    overflow: hidden;
    position: relative;
    align-self: stretch !important;
  }
  .wp-block-media-text .wp-block-media-text__media img {
    min-height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: none;
    width: auto;
  }
}

/* ==========================================================================
   # Group Block
========================================================================== */
.page-content .wp-block-group {
  max-width: 1760px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10rem;
}

.page-content .wp-block-group .wp-block-columns {
  padding-left: 0;
}

@media (min-width: 1920px) {
  .page-content .wp-block-group {
    padding-left: 10rem;
  }
}

/* ==========================================================================
   # Cover
========================================================================== */
.wp-block-cover {
  margin: 5rem auto;
}

/* ==========================================================================
   # Columns
========================================================================== */
.page-content .wp-block-columns {
  max-width: 1760px;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 1024px) {
  .page-content .wp-block-columns {
    padding-left: 10rem;
  }
}

.nav-collapsed {
  background: #1c1b21;
  position: fixed;
  z-index: 998;
  top: 0;
  width: 100%;
  left: -100%;
  padding: 6rem 7.5rem 0;
  transition: all 300ms ease-in-out;
  height: 100vh;
  display: flex;
  align-items: center;
}

.nav-collapsed a {
  font-family: "mexcellent", sans-serif;
  font-size: 4.5rem;
}

.nav-collapsed a:hover, .nav-collapsed a:focus {
  animation: neon 2.5s linear infinite;
}

.nav-collapsed a:hover svg, .nav-collapsed a:focus svg {
  animation: neon 2.5s linear infinite;
}

.nav-collapsed .menu-item-has-children a {
  display: flex;
  align-items: center;
}

.nav-collapsed .menu-item-has-children a .icon {
  max-width: 5rem;
  max-height: 5rem;
  margin-left: 3rem;
  position: relative;
  z-index: 5;
  fill: #ffeb5c;
}

.nav-collapsed .menu-item-has-children a:hover .icon, .nav-collapsed .menu-item-has-children a:focus .icon {
  animation: move-right 1.5s linear infinite;
}

.nav-collapsed .menu-item-has-children.submenu-open .nav-child {
  display: block;
}

.nav-collapsed .menu-item-has-children .nav-child {
  display: none;
}

.nav-collapsed .menu-item-has-children .nav-child li {
  list-style: none;
}

.nav-collapsed .menu-item-has-children .nav-child li a {
  font-size: 4.5rem;
}

.nav-collapsed .site-logo {
  margin: 4rem 0;
  opacity: 0.7;
  max-width: 32rem;
}

.nav-collapsed.is-open {
  left: 0;
}

.nav-collapsed.nav-has-background-image {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 20% 50%;
}

.nav-collapsed .social-media {
  opacity: 0.7;
}

.nav-collapsed .social-media h4, .nav-collapsed .social-media .heading-size-4 {
  font-family: "Open Sans", sans-serif;
  font-weight: bold;
  font-size: 1.8rem;
}

@media (min-width: 768px) {
  .nav-collapsed {
    padding: 0 5rem 0 18vw;
  }
  .nav-collapsed .wp-block-columns {
    max-width: 100rem;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
  }
  .nav-collapsed .primary-nav .menu a {
    font-size: 7vmin;
    line-height: 1.5;
  }
  .nav-collapsed .site-logo {
    margin-left: auto;
    margin-bottom: 5rem;
  }
  .nav-collapsed .social-media h4, .nav-collapsed .social-media .heading-size-4 {
    font-size: 2.7rem;
    text-align: right;
  }
  .nav-collapsed .social-media .social-media-menu {
    justify-content: flex-end;
    margin-top: 2rem;
  }
}

/*
   Social Media Nav
*/
.social-media-nav .social-media-menu {
  display: flex;
}

.social-media-nav li:not(:last-of-type) {
  margin-right: 1rem;
}

.social-media-nav .social-media-menu__link {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 5rem;
  height: 5rem;
  border: 0.3rem solid #fff;
  transition: all 300ms ease-in-out;
  border-radius: 50%;
  position: relative;
  opacity: 0.8;
}

.social-media-nav .social-media-menu__link::before {
  content: '';
  width: 4.2rem;
  height: 4.2rem;
  position: absolute;
  border-radius: 50%;
  border: 0.3rem solid #fff;
}

.social-media-nav .social-media-menu__link svg {
  width: 100%;
  height: 100%;
  max-width: 2rem;
  max-height: 2rem;
  fill: #fff;
}

.social-media-nav .social-media-menu__link:hover, .social-media-nav .social-media-menu__link:focus {
  opacity: 1;
}

.menu-button {
  font: inherit;
  display: inline-block;
  overflow: visible;
  margin: 0;
  padding: 0 3rem;
  cursor: pointer;
  transition-timing-function: linear;
  transition-duration: 0.15s;
  transition-property: opacity, filter, background;
  text-transform: none;
  color: inherit;
  border: 0;
  background-color: transparent;
  z-index: 100;
}

.menu-button:focus {
  outline: none;
}

.menu-button--box {
  position: relative;
  display: inline-block;
  width: 2rem;
  height: 1.5rem;
}

.menu-button--inner::before, .menu-button--inner::after {
  display: block;
  content: '';
}

.menu-button--inner, .menu-button--inner::before, .menu-button--inner::after {
  position: absolute;
  left: 0;
  width: 2.6rem;
  height: 0.3rem;
  transition-timing-function: ease;
  transition-duration: 0.15s;
  transition-property: transform;
  background-color: #ffeb5c;
}

.menu-button--inner {
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition-duration: 75ms;
  top: 50%;
  display: block;
}

.menu-button--inner::before {
  transition: top 75ms ease 0.12s, opacity 75ms ease;
  top: -0.75rem;
}

.menu-button--inner::after {
  transition: top 75ms ease 0.12s, opacity 75ms ease;
  top: 0.75rem;
}

.menu-open {
  background: #ffeb5c;
}

.menu-open .menu-button--inner,
.menu-open .menu-button--inner::before,
.menu-open .menu-button--inner::after {
  background: #1c1b21;
}

.menu-open .menu-button--inner {
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  transform: rotate(45deg);
}

.menu-open .menu-button--inner::before, .menu-open .menu-button--inner::after {
  top: 0;
  transition: top 75ms ease, transform ease 0.15s;
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  transform: rotate(90deg);
}

body .site-main .main-title-extra {
  font-family: "Open Sans", sans-serif;
  font-weight: 800;
  color: #333333;
  text-align: center;
  margin: 6.8rem 0 0;
  padding: 1rem;
  font-size: 2.7rem;
}

@media (min-width: 1024px) {
  body .site-main .main-title-extra {
    margin: 0 0 0 8rem;
    font-size: 4rem;
  }
}

@media (min-width: 1920px) {
  body .site-main .main-title-extra {
    margin: 0 0 0 13rem;
  }
}

.term-extra-button {
  margin-top: 7rem;
  justify-content: center;
}

.accordion-main {
  margin: 10rem 0;
}

.accordion-main .accordion-item {
  border-bottom: 0.1rem solid #fff;
}

.accordion-main .accordion-item:last-child {
  border-bottom: 0;
}

.accordion-main .accordion-item .accordion-title {
  position: relative;
  padding: 2rem 4rem 2rem 2rem;
  font-family: "Open Sans", sans-serif;
  font-weight: normal;
  font-size: 2rem;
  margin: 0;
  cursor: pointer;
  color: #fff;
}

.accordion-main .accordion-item .accordion-title .icon {
  position: absolute;
  top: 0;
  right: 2rem;
  width: 1.5rem;
  height: 100%;
  transition: all 300ms ease-in-out;
  fill: currentColor;
}

.accordion-main .accordion-item .accordion-description {
  display: none;
  padding: 0 2rem 2rem;
}

.accordion-main .accordion-item .accordion-description p {
  margin: 0 0 2rem 0;
}

.accordion-main .accordion-item .accordion-description p:last-child {
  margin-bottom: 0;
}

.accordion-main .accordion-item.active .accordion-title .icon {
  transform: scaleY(-1);
}

.single-projects h2, .single-projects .heading-size-2,
.single-projects h3, .single-projects .heading-size-3,
.single-projects h4, .single-projects .heading-size-4,
.single-projects h5, .single-projects .heading-size-5,
.single-projects h6 .heading-size-6,
.single-career h2,
.single-career .heading-size-2,
.single-career h3,
.single-career .heading-size-3,
.single-career h4,
.single-career .heading-size-4,
.single-career h5,
.single-career .heading-size-5,
.single-career h6 .heading-size-6,
.single-post h2,
.single-post .heading-size-2,
.single-post h3,
.single-post .heading-size-3,
.single-post h4,
.single-post .heading-size-4,
.single-post h5,
.single-post .heading-size-5,
.single-post h6 .heading-size-6 {
  font-family: "Open Sans", sans-serif;
  font-weight: 800;
  margin: 3rem 0 1rem 0;
}

.single-projects p,
.single-career p,
.single-post p {
  margin: 0 0 2rem 0;
}

.single-projects h2, .single-projects .heading-size-2,
.single-career h2,
.single-career .heading-size-2,
.single-post h2,
.single-post .heading-size-2 {
  font-size: 4rem;
}

.single-projects h3, .single-projects .heading-size-3,
.single-career h3,
.single-career .heading-size-3,
.single-post h3,
.single-post .heading-size-3 {
  font-size: 3.5rem;
}

.single-projects h4, .single-projects .heading-size-4,
.single-career h4,
.single-career .heading-size-4,
.single-post h4,
.single-post .heading-size-4 {
  font-size: 3rem;
}

.single-projects h5, .single-projects .heading-size-5,
.single-career h5,
.single-career .heading-size-5,
.single-post h5,
.single-post .heading-size-5 {
  font-size: 2.5rem;
}

.single-projects h6, .single-projects .heading-size-6,
.single-career h6,
.single-career .heading-size-6,
.single-post h6,
.single-post .heading-size-6 {
  font-size: 2rem;
}

.single-projects ol,
.single-projects ul,
.single-career ol,
.single-career ul,
.single-post ol,
.single-post ul {
  padding: 0 2rem;
  margin-bottom: 2rem;
}

.single-projects ol li,
.single-projects ul li,
.single-career ol li,
.single-career ul li,
.single-post ol li,
.single-post ul li {
  margin-bottom: 0.25rem;
}

.single-projects ol li::marker,
.single-projects ul li::marker,
.single-career ol li::marker,
.single-career ul li::marker,
.single-post ol li::marker,
.single-post ul li::marker {
  color: #ffeb5c;
}

.single-projects .block-hero .post-date-category,
.single-career .block-hero .post-date-category,
.single-post .block-hero .post-date-category {
  color: #f5e158;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 1.6rem;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.2rem;
  margin-bottom: 2rem;
}

.single-projects .block-hero .post-date-category .spacer,
.single-career .block-hero .post-date-category .spacer,
.single-post .block-hero .post-date-category .spacer {
  margin: 0 1rem;
  display: none;
}

@media (min-width: 768px) {
  .single-projects .block-hero .post-date-category,
  .single-career .block-hero .post-date-category,
  .single-post .block-hero .post-date-category {
    flex-direction: row;
    justify-content: center;
    align-items: auto;
  }
  .single-projects .block-hero .post-date-category .spacer,
  .single-career .block-hero .post-date-category .spacer,
  .single-post .block-hero .post-date-category .spacer {
    display: inline-block;
  }
}

.single-projects .block-hero .post-date-category .hero-post-categories,
.single-projects .block-hero .post-date-category p,
.single-career .block-hero .post-date-category .hero-post-categories,
.single-career .block-hero .post-date-category p,
.single-post .block-hero .post-date-category .hero-post-categories,
.single-post .block-hero .post-date-category p {
  margin: 0;
}

.single-projects .block-hero .hero-post-categories,
.single-career .block-hero .hero-post-categories,
.single-post .block-hero .hero-post-categories {
  padding: 0;
  display: flex;
  flex-flow: row wrap;
}

.single-projects .block-hero .hero-post-categories li,
.single-career .block-hero .hero-post-categories li,
.single-post .block-hero .hero-post-categories li {
  list-style: none;
}

.single-projects .block-hero .hero-post-categories li:not(:first-of-type):last-of-type::before,
.single-career .block-hero .hero-post-categories li:not(:first-of-type):last-of-type::before,
.single-post .block-hero .hero-post-categories li:not(:first-of-type):last-of-type::before {
  content: ', ';
}

.single-projects .block-hero .slogan,
.single-career .block-hero .slogan,
.single-post .block-hero .slogan {
  position: static;
  transform: none;
}

.single-projects .post-navigation,
.single-projects .post-content.container,
.single-career .post-navigation,
.single-career .post-content.container,
.single-post .post-navigation,
.single-post .post-content.container {
  max-width: 115rem;
}

.single-projects .post-navigation,
.single-career .post-navigation,
.single-post .post-navigation {
  display: flex;
  justify-content: space-between;
  margin: 5rem auto 9rem auto;
  font-size: 1.6rem;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 0.2rem;
}

.single-projects .post-navigation img,
.single-career .post-navigation img,
.single-post .post-navigation img {
  width: 100%;
}

.single-projects .post-navigation a,
.single-career .post-navigation a,
.single-post .post-navigation a {
  display: flex;
  align-items: center;
}

.single-projects .post-navigation .prev:hover .icon,
.single-projects .post-navigation .prev:focus .icon,
.single-career .post-navigation .prev:hover .icon,
.single-career .post-navigation .prev:focus .icon,
.single-post .post-navigation .prev:hover .icon,
.single-post .post-navigation .prev:focus .icon {
  transform: scaleX(-1) translateX(5px);
}

.single-projects .post-navigation .next:hover .icon,
.single-projects .post-navigation .next:focus .icon,
.single-career .post-navigation .next:hover .icon,
.single-career .post-navigation .next:focus .icon,
.single-post .post-navigation .next:hover .icon,
.single-post .post-navigation .next:focus .icon {
  transform: translateX(5px);
}

.single-projects .post-navigation .prev .icon,
.single-career .post-navigation .prev .icon,
.single-post .post-navigation .prev .icon {
  transform: scaleX(-1);
  margin-right: 1rem;
}

.single-projects .post-navigation .next .icon,
.single-career .post-navigation .next .icon,
.single-post .post-navigation .next .icon {
  margin-left: 1rem;
}

.single-projects .post-navigation .icon,
.single-career .post-navigation .icon,
.single-post .post-navigation .icon {
  transition: all 300ms ease-in-out;
  width: 1.8rem;
  height: 1.5rem;
}

.single-projects .container {
  max-width: 130rem;
}

.single-projects .project-header {
  text-transform: uppercase;
  margin-bottom: 6rem;
}

.single-projects .project-header .label {
  color: #ffeb5c;
  font-weight: 800;
  letter-spacing: 0.2rem;
  margin-bottom: 1rem;
  display: block;
}

.single-projects .project-header p {
  font-family: "mexcellent", sans-serif;
  font-size: 3.5rem;
  line-height: 1;
}

.single-projects .project-sign-type {
  padding: 0;
}

.single-projects .project-sign-type li {
  list-style: none;
  font-family: "mexcellent", sans-serif;
  font-size: 3.5rem;
  line-height: 1;
}

.single-projects .post-navigation {
  margin: 0;
  max-width: none;
  padding: 0;
  display: flex;
  flex-direction: column;
}

.single-projects .tile {
  position: relative;
  overflow: hidden;
}

.single-projects .tile .content {
  position: absolute;
  z-index: 5;
  bottom: 2rem;
  right: 2rem;
  text-align: right;
  max-width: 50%;
}

.single-projects .tile .title,
.single-projects .tile img {
  transition: all 300ms ease-in-out;
}

.single-projects .tile .title {
  margin: 0;
  font-family: "mexcellent", sans-serif;
}

.single-projects .tile img {
  opacity: 0.4;
}

.single-projects .tile:hover img, .single-projects .tile:focus img {
  transform: scale(1.1);
}

.single-projects .tile:hover .title, .single-projects .tile:focus .title {
  color: #ffeb5c;
}

.single-career .location {
  font-size: 2.7rem;
  color: #ffeb5c;
  font-weight: bold;
  text-transform: uppercase;
  margin: 5rem 0 0 0;
}

@media (min-width: 768px) {
  .single-projects .project-header .wp-block-columns {
    justify-content: flex-start;
  }
  .single-projects .tile .content {
    bottom: 5.5rem;
    right: 5.5rem;
  }
  .single-projects .post-navigation {
    flex-direction: row;
  }
  .single-projects .post-navigation a {
    flex-basis: 50%;
  }
}

@media (min-width: 1024px) {
  .single-projects .project-header .wp-block-columns .wp-block-column {
    flex: 0 1 auto !important;
    max-width: 40rem;
    gap: 0;
  }
  .single-projects .project-header .wp-block-columns .wp-block-column:not(:last-of-type) {
    padding-right: 3rem;
    border-right: 0.1rem solid #514f59;
  }
  .single-projects .project-header .wp-block-columns .wp-block-column:not(:first-of-type) {
    padding-left: 3rem;
  }
}

.block-archive {
  position: relative;
}

.block-archive .entry-header {
  font-size: 12vmin;
  text-align: center;
  line-height: 1;
}

.block-archive .intro {
  margin: 7rem 3.3rem 0 3.3rem;
  position: relative;
}

.block-archive .intro .intro-content {
  text-align: center;
  color: black;
  padding: 4rem 2.5rem;
  background: #fff;
  position: relative;
  z-index: 2;
}

.block-archive .intro .intro-content .wp-block-button {
  margin-top: 3.5rem;
}

.block-archive .intro::before {
  content: '';
  background: url("../assets/images/dots.png");
  width: 13.5rem;
  height: 13.5rem;
  position: absolute;
  z-index: 1;
  top: -5rem;
  left: -2rem;
}

.block-archive .term-details h2, .block-archive .term-details .heading-size-2,
.block-archive .term-details h3, .block-archive .term-details .heading-size-3,
.block-archive .term-details h4, .block-archive .term-details .heading-size-4,
.block-archive .term-details h5, .block-archive .term-details .heading-size-5,
.block-archive .term-details h6 .heading-size-6 {
  font-family: "Open Sans", sans-serif;
  font-weight: 800;
  margin: 3rem 0 1rem 0;
}

.block-archive .term-details p {
  margin: 0 0 2rem 0;
}

.block-archive .term-details h2, .block-archive .term-details .heading-size-2 {
  font-size: 4rem;
}

.block-archive .term-details h3, .block-archive .term-details .heading-size-3 {
  font-size: 3.5rem;
}

.block-archive .term-details h4, .block-archive .term-details .heading-size-4 {
  font-size: 3rem;
}

.block-archive .term-details h5, .block-archive .term-details .heading-size-5 {
  font-size: 2.5rem;
}

.block-archive .term-details h6, .block-archive .term-details .heading-size-6 {
  font-size: 2rem;
}

.block-archive .term-details ol,
.block-archive .term-details ul {
  padding: 0 2rem;
  margin-bottom: 2rem;
}

.block-archive .term-details ol li,
.block-archive .term-details ul li {
  margin-bottom: 0.25rem;
}

.block-archive .term-details ol li::marker,
.block-archive .term-details ul li::marker {
  color: #ffeb5c;
}

.block-archive .archive-item {
  position: relative;
  overflow: hidden;
  display: block;
}

.block-archive .archive-item .item-header {
  transition: all 300ms ease-in-out;
  margin-bottom: 0.5rem;
  font-size: 3.8rem;
}

.block-archive .archive-item img {
  position: relative;
  z-index: 3;
  width: 100%;
  display: block;
  opacity: 0.4;
  transition: all 300ms ease-in-out;
}

.block-archive .archive-item::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 4;
  top: 50%;
  left: 50%;
  pointer-events: none;
  background: radial-gradient(circle, rgba(28, 27, 33, 0.8) 0%, rgba(0, 0, 0, 0) 50%);
}

.block-archive .archive-item:hover img, .block-archive .archive-item:focus img {
  transform: scale(1.1);
}

.block-archive .archive-item:hover .item-header, .block-archive .archive-item:focus .item-header {
  color: #ffeb5c;
}

.block-archive .slick-slide {
  opacity: 0.4;
  transition: all 300ms ease-in-out;
}

.block-archive .slick-slide a {
  display: block;
  height: 100%;
}

.block-archive .slick-slide.slick-current.slick-active {
  opacity: 1;
}

.block-archive .slick-slide.slick-current.slick-active .item-header {
  color: #ffeb5c;
}

.block-archive .slick-list {
  padding: 0 3.3rem 0 3.3rem;
}

.block-archive .archive-items.tiles .archive-item {
  position: relative;
}

.block-archive .archive-items.tiles .archive-item .item-content {
  z-index: 5;
  position: absolute;
  bottom: 2rem;
  display: block;
  right: 2rem;
}

.block-archive .archive-items.tiles .archive-item .excerpt {
  color: white;
  margin-top: 0;
}

.block-archive .archive-items.masonary {
  display: grid;
}

.block-archive .archive-items.masonary .item-header {
  position: static;
  font-size: 3rem;
  text-align: left;
  font-family: "Open Sans", sans-serif;
  font-weight: 800;
  margin-top: 0;
  text-transform: capitalize;
}

.block-archive .archive-items.masonary .item-content {
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 2.5rem;
  z-index: 5;
}

.block-archive .archive-items.masonary .archive-item:hover .item-header,
.block-archive .archive-items.masonary .archive-item:focus .item-header {
  color: #fff;
}

.block-archive .archive-items.masonary .item-date {
  text-transform: uppercase;
  font-weight: 800;
  margin-bottom: 0.5rem;
  font-size: 1.6rem;
}

.block-archive .archive-items.masonary .excerpt {
  color: #fff;
  margin: 0;
}

.block-archive .archive-items.masonary .wp-block-button {
  margin-top: 2rem;
}

.block-archive--page .archive-items.tiles .archive-item img {
  position: absolute;
  max-width: none;
  min-height: 100%;
}

.block-archive--page .archive-items.tiles .archive-item .excerpt {
  margin: 1rem 0 1rem auto;
}

.block-archive--page .archive-items.tiles .archive-item .item-content {
  min-height: 70vw;
  z-index: 5;
  bottom: auto;
  right: auto;
  position: relative;
  padding: 5rem 1.5rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  text-align: right;
  align-items: flex-end;
}

.block-archive--career .archive-items.tiles .archive-item .location {
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.2rem;
}

.block-archive--career .archive-items.tiles .archive-item .item-content {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  text-align: right;
  max-width: 60%;
}

.block-archive--career .archive-items.tiles .archive-item .item-header {
  margin: 1rem 0;
}

.block-archive--career .archive-items.tiles .archive-item .wp-block-button {
  margin-left: auto;
}

.block-archive .archive-items.archive-featured .archive-item {
  position: relative;
}

.block-archive .archive-items.archive-featured .item-header {
  z-index: 5;
  position: absolute;
  bottom: 2rem;
  right: 2rem;
}

.block-archive .archive-items.archive-featured .item-header.has-text-align-right {
  text-align: right;
}

.block-archive .archive-items.archive-featured .item-header.has-text-align-center {
  text-align: center;
}

.block-archive .archive-items.archive-featured .item-header.has-text-align-left {
  text-align: left;
}

@media (min-width: 1024px) {
  .block-archive .archive-items.archive-featured {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
  .block-archive .archive-items.archive-featured img {
    height: auto;
  }
}

.archive-load-more {
  display: flex;
  align-items: center;
  transition: all 0.3s ease-in-out;
  border-radius: 4rem;
  border: 0.3rem solid;
  border-color: inherit;
  font-size: 1.6rem;
  font-weight: normal;
  font-style: normal;
  padding: 0.8rem 4rem;
  color: #ffeb5c;
  background: none;
  cursor: pointer;
  outline: none;
  margin: 5rem auto 5rem auto;
}

.archive-load-more datafield {
  display: none;
}

.archive-load-more .icon {
  width: 1.8rem;
  height: 1.8rem;
  margin-right: 1rem;
  transform: rotate(90deg);
}

.archive-load-more:hover .icon, .archive-load-more:focus .icon {
  animation-name: bounce-3;
  animation-timing-function: ease;
}

@media (min-width: 1440px) {
  .archive-load-more {
    position: absolute;
    z-index: 5;
    right: 0rem;
    bottom: 10rem;
    transform: rotate(-90deg);
    margin: 0;
  }
  .archive-load-more .icon {
    transform: scaleX(-1);
    transition: all 0.3s ease-in-out;
    animation-duration: 2s;
    animation-iteration-count: infinite;
  }
}

@keyframes bounce-down {
  0% {
    transform: scaleX(-1);
  }
  30% {
    transform: scaleX(-1) translateX(2rem);
  }
  50% {
    transform: scaleX(-1) translateX(0);
  }
  100% {
    transform: scaleX(-1) translateX(0);
  }
}

@keyframes bounce-3 {
  0% {
    transform: scaleX(-1);
  }
  30% {
    transform: scaleX(-1) translateX(2rem);
  }
  50% {
    transform: scaleX(-1) translateX(0);
  }
  100% {
    transform: scaleX(-1) translateX(0);
  }
}

.block-archive .archive-items.archive-items--odd .header-image-full {
  display: none;
}

@media (min-width: 768px) {
  .block-archive .archive-items.tiles {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
  .block-archive .archive-items.archive-items--odd .archive-item:last-of-type {
    grid-column: 1 / span 2;
    height: 45rem;
  }
  .block-archive .archive-items.archive-items--odd .archive-item:last-of-type img {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    min-height: 100%;
  }
  .block-archive .archive-items.archive-items--odd .archive-item:last-of-type .header-image-full {
    display: block;
  }
  .block-archive--page .archive-items.tiles .archive-item .item-content {
    min-height: 50vw;
  }
}

@media (min-width: 1024px) {
  .block-archive .archive-items.archive-items--odd .archive-item:last-of-type {
    height: 63rem;
  }
  .block-archive .entry-content {
    display: flex;
    align-items: stretch;
  }
  .block-archive .entry-header {
    font-size: 8vmin;
    text-align: left;
    margin: 0 0 3rem 8rem;
    line-height: 0.9;
    align-self: center;
  }
  .block-archive .entry-header,
  .block-archive .intro {
    flex-basis: 50%;
  }
  .block-archive .intro {
    margin: 0 0 0 7rem;
  }
  .block-archive .intro::before {
    left: -8rem;
    top: -4rem;
  }
  .block-archive .intro .intro-content {
    height: 100%;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    text-align: left;
    padding: 8rem 12rem 8rem 8rem;
  }
  .block-archive .archive-item img {
    opacity: 0.4;
    height: 100%;
    transition: all 300ms ease-in-out;
  }
  .block-archive .archive-item .item-header {
    font-size: 6rem;
  }
  .block-archive .archive-item:hover .item-header, .block-archive .archive-item:focus .item-header {
    color: #ffeb5c;
  }
  .block-archive .archive-items.masonary {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
  .block-archive--page .archive-items.tiles .archive-item img {
    position: static;
    min-height: none;
  }
  .block-archive--page .archive-items.tiles .archive-item .item-content {
    min-height: 0;
    position: absolute;
    padding: 0;
    height: 100%;
    bottom: 2rem;
    right: 2rem;
    max-width: 80%;
  }
  .block-archive .archive-items.tiles .archive-item .excerpt {
    text-align: right;
  }
  .block-archive .archive-items.tiles img {
    display: block;
    height: auto;
  }
  .block-archive .archive-items.masonary .archive-item .item-content {
    position: absolute;
  }
}

@media (min-width: 1440px) {
  .block-archive .entry-header {
    font-size: 15.1rem;
  }
  .block-archive .archive-items.tiles .archive-item .item-content {
    right: 5.5rem;
    bottom: 5.5rem;
  }
  .block-archive .archive-items.masonary {
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(3, 1fr);
    align-content: space-around;
    justify-content: space-between;
    height: 125rem;
  }
  .block-archive .archive-items.masonary .item-content {
    max-width: 75%;
  }
  .block-archive .archive-items.masonary .archive-item:nth-of-type(1n) {
    grid-column: 1 / span 2;
    grid-row: 1 / span 2;
  }
  .block-archive .archive-items.masonary .archive-item:nth-of-type(2n) {
    grid-column: 3;
    grid-row: 1;
  }
  .block-archive .archive-items.masonary .archive-item:nth-of-type(3n) {
    grid-column: 4;
    grid-row: 1;
  }
  .block-archive .archive-items.masonary .archive-item:nth-of-type(4n) {
    grid-column: 1;
    grid-row: 3;
  }
  .block-archive .archive-items.masonary .archive-item:nth-of-type(5n) {
    grid-column: 2;
    grid-row: 3;
  }
  .block-archive .archive-items.masonary .archive-item:nth-of-type(6n) {
    grid-column: 3 / span 4;
    grid-row: 2 / span 4;
  }
  .block-archive .archive-items.masonary .archive-item:nth-of-type(2n) .excerpt,
  .block-archive .archive-items.masonary .archive-item:nth-of-type(3n) .excerpt,
  .block-archive .archive-items.masonary .archive-item:nth-of-type(4n) .excerpt,
  .block-archive .archive-items.masonary .archive-item:nth-of-type(5n) .excerpt {
    display: none;
  }
  .block-archive .archive-items.masonary .archive-item:nth-of-type(6n) .excerpt {
    display: block;
  }
}

.block-list {
  margin-bottom: 5rem;
}

@media (min-width: 1024px) {
  .block-list {
    margin-bottom: 15rem;
  }
}

.block-list h2, .block-list .heading-size-2,
.block-list h3, .block-list .heading-size-3 {
  line-height: 0.9;
  font-family: "Open Sans", sans-serif;
  font-weight: 800;
}

.block-list h2, .block-list .heading-size-3 {
  font-size: 12vmin;
}

.block-list h3, .block-list .heading-size-3 {
  font-size: 7.5vmin;
  color: #ffeb5c;
}

.block-list .list {
  margin: 3rem 0;
}

.block-list .list ul {
  padding: 0;
  counter-reset: list-item;
}

.block-list .list li {
  counter-increment: list-item;
  font-size: 3.2rem;
  font-weight: 800;
  list-style: none;
  margin-bottom: 1rem;
  opacity: 0.6;
  transition: all 300ms ease-in-out;
}

.block-list .list li::before {
  content: '';
  width: 1rem;
  height: 1rem;
  background: #ffeb5c;
  display: inline-block;
  vertical-align: middle;
  margin-right: 1rem;
}

.block-list .list li::after {
  content: "0" counter(list-item);
  font-size: 1.2rem;
  position: absolute;
}

.block-list .list li:hover, .block-list .list li:focus {
  opacity: 1;
}

.block-list .list li.highlight {
  opacity: 1;
}

.block-list .list + .wp-block-button {
  display: flex;
  justify-content: center;
  margin: 5rem 0 7rem 0;
}

@media (min-width: 768px) {
  .block-list h2, .block-list .heading-size-2 {
    font-size: 14.1rem;
  }
  .block-list h3, .block-list .heading-size-3 {
    font-size: 8.4rem;
    margin-bottom: 3rem;
  }
  .block-list p {
    font-size: 2.1rem;
    margin-bottom: 6rem;
  }
  .block-list .list li {
    font-size: 5rem;
  }
  .block-list .list.list-horizontal ul {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
  }
  .block-list .list.list-horizontal ul li {
    text-align: left;
    margin: 0 1vmin 1vmin;
  }
  .block-list .list.list-horizontal ul li::after {
    content: none;
  }
}

.block-location {
  max-width: 30rem;
  margin: 5rem auto;
}

.block-location a {
  color: currentColor;
}

.block-location a:hover {
  color: #ffeb5c;
}

.block-location .icon {
  margin-right: 2rem;
  width: 2.6rem;
  height: 3.3rem;
  fill: #ffeb5c;
}

.block-location .location__name {
  font-size: 5rem;
  font-family: "Open Sans", sans-serif;
  font-weight: bold;
  text-transform: initial;
}

.block-location .location__address, .block-location .location__phone {
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  font-size: 1.8rem;
}

@media (min-width: 1024px) {
  .block-location {
    margin: 0 auto 10rem auto;
  }
}

.block-section {
  margin-bottom: 3.5rem;
}

.block-section .entry-content {
  text-align: center;
}

.block-section .entry-content h2, .block-section .entry-content .heading-size-2,
.block-section .entry-content h3, .block-section .entry-content .heading-size-3,
.block-section .entry-content h4, .block-section .entry-content .heading-size-4,
.block-section .entry-content h5 .heading-size-5 {
  font-family: "Open Sans", sans-serif;
  font-weight: bold;
  font-size: 2.4rem;
  text-transform: initial;
}

.block-section .entry-content h3, .block-section .entry-content .heading-size-3 {
  color: #ffeb5c;
}

.block-section .wp-block-button {
  margin: 3.5rem auto;
}

.block-section img {
  margin-left: 2.5rem;
}

@media (min-width: 768px) {
  .block-section {
    overflow: hidden;
    position: relative;
  }
  .block-section::after, .block-section::before {
    position: absolute;
    z-index: 2;
    content: '';
    pointer-events: none;
    background-color: transparent !important;
    height: 50%;
    left: 0;
    width: 100%;
  }
  .block-section::before {
    top: 0;
    background: linear-gradient(to bottom, #1c1b21 0%, rgba(28, 27, 33, 0) 25%);
  }
  .block-section::after {
    background: linear-gradient(0deg, #1c1b21 0%, rgba(28, 27, 33, 0) 80%);
    bottom: 0;
    top: auto;
  }
  .block-section img {
    position: absolute;
    z-index: 0;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  .block-section .entry-content {
    position: relative;
    z-index: 5;
    max-width: 45%;
  }
  .block-section .entry-content h2, .block-section .entry-content .heading-size-2,
  .block-section .entry-content h3, .block-section .entry-content .heading-size-3,
  .block-section .entry-content h4, .block-section .entry-content .heading-size-4,
  .block-section .entry-content h5, .block-section .entry-content .heading-size-5 {
    font-size: 2.7rem;
  }
  .block-section .entry-content.align-right {
    margin: 5vw 2rem 5rem auto;
    text-align: right;
  }
  .block-section .entry-content.align-right .wp-block-button {
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }
  .block-section .entry-content.align-center {
    margin: 5rem auto;
  }
  .block-section .entry-content.align-center > * {
    text-align: center;
  }
  .block-section .entry-content.align-center .wp-block-button {
    display: flex;
    justify-content: center;
    width: 100%;
  }
  .block-section .entry-content.align-left {
    margin: 5rem auto 5rem 0;
  }
  .block-section .entry-content.align-left > * {
    text-align: left;
  }
  .block-section .entry-content.align-left .wp-block-button {
    display: flex;
    justify-content: flex-start;
    width: 100%;
  }
}

@media (min-width: 1440px) {
  .block-section img {
    min-height: 100%;
    max-width: none;
    width: 100%;
  }
  .block-section .entry-content.align-right {
    margin: 15vw 2rem 15vw auto;
  }
  .block-section .entry-content.align-center {
    margin: 15vw auto;
  }
  .block-section .entry-content.align-left {
    margin: 15vw auto 15vw 0;
  }
}

@media (min-width: 1600px) {
  .block-section .container {
    margin: 0 auto;
  }
}

.page-content .wp-block-button .wp-block-button__link,
.page-content .wp-block-button__link:visited {
  display: block;
  transition: all 0.3s ease-in-out;
  border-radius: 4rem;
  border: 0.3rem solid;
  border-color: inherit;
  font-size: 1.6rem;
  font-weight: normal;
  font-style: normal;
  padding: 0.8rem 4rem;
  color: #ffeb5c;
  background: none;
}

.page-content .wp-block-button {
  display: inline-block;
}

.page-content .wp-block-button.has-style-outline-black {
  border-color: black;
}

.page-content .wp-block-button.has-style-outline-black .wp-block-button__link {
  color: black;
  padding: 0.8rem 5.5rem;
}

.page-content .wp-block-button.has-style-outline-black:hover, .page-content .wp-block-button.has-style-outline-black:focus {
  border: #ffeb5c;
}

.page-content .wp-block-button.has-style-outline-black:hover .wp-block-button__link, .page-content .wp-block-button.has-style-outline-black:focus .wp-block-button__link {
  color: black !important;
}

.page-content .wp-block-button__link:hover, .page-content .wp-block-button__link:focus {
  background: #ffeb5c;
  color: black;
  border-color: #ffeb5c;
}

.flexible-gallery {
  margin: 1.5rem;
}

.flexible-gallery img {
  transition: all 300ms ease-in-out;
  cursor: pointer;
  opacity: 0.5;
  width: 100%;
  margin-bottom: 1.5rem;
}

.flexible-gallery img:hover, .flexible-gallery img:focus {
  opacity: 1;
}

@media (min-width: 768px) {
  .flexible-gallery {
    column-count: 2;
    column-gap: 1.5rem;
  }
}

@media (min-width: 1024px) {
  .flexible-gallery {
    column-count: 3;
  }
}

.flexible-text h2, .flexible-text .heading-size-2,
.flexible-text h1, .flexible-text .heading-size-1 {
  color: #ffeb5c;
}

.flexible-text ol,
.flexible-text ul {
  padding: 0 2rem;
  margin-bottom: 2rem;
}

.flexible-text ol li,
.flexible-text ul li {
  margin-bottom: 0.25rem;
}

.flexible-text ol li::marker,
.flexible-text ul li::marker {
  color: #ffeb5c;
}

.flexible-media-text {
  margin-bottom: 15rem;
}

.flexible-media-text h2, .flexible-media-text .heading-size-2 {
  font-size: 8vmin;
  color: #ffeb5c;
  margin-bottom: 2rem;
}

.flexible-media-text h3, .flexible-media-text .heading-size-3 {
  font-size: 2.7rem;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  color: #ffeb5c;
  margin: 6rem auto 4rem auto;
}

@media (min-width: 1024px) {
  .flexible-media-text .wp-block-media-text h2, .flexible-media-text .wp-block-media-text .heading-size-2 {
    font-size: 8rem;
    text-transform: initial;
  }
  .flexible-media-text .wp-block-media-text .wp-block-media-text__content {
    padding-left: 9rem;
    margin-left: auto;
  }
}

.flexible-form__blurb {
  font-size: 2.1rem;
}

.flexible-form .container {
  max-width: 115rem;
}

.flexible-form .nf-form-cont {
  max-width: 78rem;
  margin: 5rem auto;
}

.flexible-form h2, .flexible-form .heading-size-2 {
  font-size: 8vmin;
}

.flexible-form h3, .flexible-form .heading-size-3 {
  font-size: 2.2rem;
}

.block-hero {
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
}

.block-hero--half {
  max-height: 30rem;
  justify-content: flex-end;
}

.block-hero--half .slogan {
  margin: 0 !important;
  padding: 0 1.5rem;
}

.block-hero--half .slogan .slogan-big {
  line-height: 1.1;
  font-size: 10vmin !important;
  text-transform: uppercase;
}

.block-hero--full {
  max-height: 60rem;
}

.block-hero--full.is-front-page {
  max-height: 100rem;
}

.block-hero .bg-video {
  min-width: 100%;
  height: 100%;
}

.block-hero .background {
  position: absolute;
  z-index: 0;
  max-width: none;
  height: 100%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.block-hero .entry-content,
.block-hero .slogan {
  position: relative;
  z-index: 3;
}

.block-hero .entry-content {
  justify-self: flex-start;
  display: flex;
  flex-direction: column;
}

.block-hero .entry-content .wp-block-button {
  margin: 6rem auto 0;
}

.block-hero .entry-content .overlay-image {
  max-width: 72rem;
  margin: 0 auto;
  padding: 0 2.5rem;
  width: 100%;
}

.block-hero .slogan {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  margin-bottom: 3rem;
  font-weight: 700;
  text-transform: capitalize;
  line-height: 0.9;
  display: flex;
  flex-direction: column;
}

.block-hero .slogan span {
  line-height: 0.8;
  word-wrap: break-word;
}

.block-hero .slogan .slogan-big {
  font-size: 13.5vmin;
}

.block-hero .slogan .slogan-small {
  font-size: 10.5vmin;
  color: #ffeb5c;
}

.block-hero.has-dark-overlay::after {
  content: '';
  background: linear-gradient(0deg, #1c1b21 5%, rgba(28, 27, 33, 0.05) 100%);
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
}

@media (min-width: 768px) {
  .block-hero--full {
    max-height: 80rem;
  }
  .block-hero--full.is-front-page {
    max-height: 110rem;
    height: 120rem;
  }
  .block-hero--half {
    max-height: 40rem;
  }
  .block-hero--half span {
    font-size: 10rem !important;
    line-height: 1.3 !important;
  }
  .block-hero .slogan .slogan-big {
    font-size: 15.2rem;
    line-height: 1;
  }
  .block-hero .slogan .slogan-small {
    font-size: 10.2rem;
  }
  .block-hero .bg-video,
  .block-hero .background {
    min-width: 100%;
    min-height: 100%;
  }
  .block-hero .bg-video {
    height: auto;
  }
  .single-projects .block-hero .slogan-big {
    font-size: 7rem;
  }
}

@media (min-width: 1024px) {
  .block-hero--half .slogan {
    padding-left: 9rem;
  }
}

@media (min-width: 1440px) {
  .block-hero--half .background {
    height: auto;
  }
}

@media (min-width: 1920px) {
  .block-hero--half .slogan {
    padding-left: 14rem;
  }
}

.imgal-container {
  line-height: 0;
  column-count: 1;
}

.imgal-img {
  width: 100%;
  height: auto;
  transition: filter 0.2s;
  margin-bottom: 1rem;
}

.imgal-img:hover {
  -webkit-filter: opacity(80%);
  filter: opacity(80%);
  cursor: pointer;
}

.imgal-modal {
  margin: auto;
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.9);
  color: #fff;
  vertical-align: middle;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.imgal-modal img {
  max-height: 80%;
  max-width: 80%;
}

#imgal-modal-close {
  display: inline;
  font-size: 4rem;
  position: absolute;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  top: 5rem;
  right: 5rem;
  cursor: pointer;
}

.imgal-modal-img {
  margin: auto;
  object-fit: contain;
}

@media (min-width: 500px) {
  .imgal-container {
    column-count: 2;
    column-gap: 1rem;
  }
}

@media (min-width: 768px) {
  .imgal-container {
    column-count: 3;
  }
}

@media (min-width: 1024px) {
  .imgal-container {
    column-count: 4;
  }
}

@media (min-width: 1440px) {
  .imgal-container {
    column-count: 5;
  }
}

.search-modal {
  position: fixed;
  z-index: 900;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.85);
  opacity: 0;
  visibility: none;
  pointer-events: none;
  transition: all 300ms ease-in-out;
}

.search-modal.is-active {
  opacity: 1;
  visibility: visible;
  pointer-events: all;
}

.search-modal__inner-container {
  width: 100%;
}

.search-modal .search-modal-close {
  position: absolute;
  top: 10rem;
  right: 5rem;
}

.search-modal input[type='text'] {
  padding: 1.15rem 2rem;
  border: 0;
  background: #fff;
  color: #333;
  outline: 0;
  font-size: 1.8rem;
  border-radius: 4rem;
  flex: 1;
  transition: all 300ms ease-in-out;
  border: 0.2rem solid transparent;
}

.search-modal input[type='text']:active, .search-modal input[type='text']:focus {
  border: 0.2rem solid #ffeb5c;
}

.search-modal button {
  border: 0;
  background: transparent;
}

.search-modal button svg {
  max-width: 2.5rem;
  max-height: 2.5rem;
  fill: #fff;
}

.search-modal button[type='submit'] {
  background: #ffeb5c;
  padding: 0.5rem 5rem;
  cursor: pointer;
  border: 0.2rem solid #ffeb5c;
  border-radius: 4rem;
  width: 100%;
  margin-top: 0.5rem;
}

.search-modal form {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  align-items: stretch;
  max-width: 80rem;
  margin: 0 auto;
}

.search-modal label {
  font-size: 2.4rem;
  font-weight: 800;
  flex-basis: 100%;
  text-align: left;
  margin-bottom: 0.5rem;
}

@media (min-width: 500px) {
  .search-modal input[type='text'] {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    flex: 0 0 80%;
  }
  .search-modal button[type='submit'] {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    padding-left: 4rem;
    padding-right: 4rem;
    width: auto;
    flex: 0 0 20%;
    margin: 0;
  }
}

@media (min-width: 768px) {
  .search-modal button[type='submit'] {
    padding-left: 4rem;
    padding-right: 4rem;
  }
}

.nf-form-cont .nf-form-layout .nf-after-form-content,
.nf-form-cont .nf-form-layout .nf-before-form-content {
  display: none;
}

.nf-form-cont .nf-form-layout .nf-field {
  position: relative;
}

.nf-form-cont .nf-form-layout .ninja-forms-field {
  height: auto !important;
}

.nf-form-cont .nf-form-layout .nf-field-element input:not([type='button']),
.nf-form-cont .nf-form-layout .nf-field-element select,
.nf-form-cont .nf-form-layout .nf-field-element textarea {
  background: transparent;
  border: 0;
  border-bottom: 0.5rem solid white;
  color: #fffffe !important;
  font-family: "Open Sans", sans-serif;
  font-size: 1.8rem;
  padding-left: 0;
}

.nf-form-cont .nf-form-layout .nf-field-element input:not([type='button'])::placeholder,
.nf-form-cont .nf-form-layout .nf-field-element select::placeholder,
.nf-form-cont .nf-form-layout .nf-field-element textarea::placeholder {
  color: #fffffe;
  opacity: 0.6;
}

.nf-form-cont .nf-form-layout .nf-field-element input:not([type='button']):hover, .nf-form-cont .nf-form-layout .nf-field-element input:not([type='button']):focus,
.nf-form-cont .nf-form-layout .nf-field-element select:hover,
.nf-form-cont .nf-form-layout .nf-field-element select:focus,
.nf-form-cont .nf-form-layout .nf-field-element textarea:hover,
.nf-form-cont .nf-form-layout .nf-field-element textarea:focus {
  background: transparent;
}

.nf-form-cont .nf-form-layout .nf-field-element input:not([type='button']):focus,
.nf-form-cont .nf-form-layout .nf-field-element select:focus,
.nf-form-cont .nf-form-layout .nf-field-element textarea:focus {
  padding-left: 2rem;
  background: rgba(255, 255, 255, 0.07);
  border-color: #ffeb5c;
}

.nf-form-cont .nf-form-layout .nf-field-element textarea {
  min-height: 25rem;
}

.nf-form-cont .nf-form-layout .nf-field .nf-field-label label {
  color: #fffffe !important;
  font-family: "Open Sans", sans-serif;
  font-size: 1.8rem;
  font-weight: normal;
}

.nf-form-cont .nf-form-layout .nf-field-element select,
.nf-form-cont .nf-form-layout .nf-field-element select:hover,
.nf-form-cont .nf-form-layout .nf-field-element select:focus,
.nf-form-cont .nf-form-layout .nf-field-element select:active {
  border-bottom: 0.5rem solid white;
  transition: all 300ms ease-in-out;
  background: url("data:image/svg+xml,<svg height='10px' width='10px' viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'><path d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/></svg>") no-repeat;
  background-color: #1c1b21;
  background-position: calc(100% - 0.75rem) center !important;
}

.nf-form-cont .nf-form-layout .nf-field-element select option {
  color: rgba(255, 255, 254, 0.6);
}

.nf-form-cont .nf-form-layout .nf-field-element select:focus {
  border-color: #ffeb5c;
}

.nf-form-cont .nf-form-layout .nf-error.field-wrap .nf-field-element:after {
  content: none;
}

.nf-form-cont .nf-form-layout .nf-error .ninja-forms-field {
  border-color: #fd3a3a !important;
}

.nf-form-cont .nf-form-layout .nf-pass.field-wrap .nf-field-element:after {
  display: none;
}

.nf-form-cont .nf-form-layout .nf-error .nf-error-msg {
  color: #fd3a3a;
  font-size: 1.4rem;
}

.nf-form-cont .nf-form-layout input[type='submit'],
.nf-form-cont .nf-form-layout input[type='button'],
.nf-form-cont .nf-form-layout .button {
  display: block;
  transition: all 0.3s ease-in-out;
  border-radius: 4rem;
  border: 0.3rem solid;
  border-color: inherit;
  font-size: 1.6rem;
  font-weight: normal;
  font-style: normal;
  padding: 0.8rem 3rem;
  height: auto;
  color: #ffeb5c;
  background: none;
  cursor: pointer;
}

.nf-form-cont .nf-form-layout input[type='submit']:hover, .nf-form-cont .nf-form-layout input[type='submit']:focus,
.nf-form-cont .nf-form-layout input[type='button']:hover,
.nf-form-cont .nf-form-layout input[type='button']:focus,
.nf-form-cont .nf-form-layout .button:hover,
.nf-form-cont .nf-form-layout .button:focus {
  background: #ffeb5c;
  border-color: #ffeb5c;
}

.nf-form-cont .nf-form-layout .file_upload-container .nf-fu-progress {
  display: none;
}

.nf-form-cont .nf-form-layout .file_upload-container .nf-fu-fileinput-button {
  background: 0;
  border-bottom: 0.5rem solid white;
  width: 100%;
  text-align: left;
  padding-left: 0;
  padding-top: 15px;
  padding-bottom: 15px;
  cursor: pointer;
  outline: none;
  color: rgba(255, 255, 254, 0.6);
}

.nf-form-cont .nf-form-layout .file_upload-container .nf-fu-fileinput-button:hover, .nf-form-cont .nf-form-layout .file_upload-container .nf-fu-fileinput-button:focus {
  color: #fff;
  border-color: #ffeb5c;
}

.lead-form {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}

.lead-form label {
  display: none;
}

.lead-form input:not([type='button']),
.lead-form select,
.lead-form textarea {
  background: transparent;
  border: 0;
  border-bottom: 0.5rem solid white;
  color: #fffffe !important;
  font-family: "Open Sans", sans-serif;
  font-size: 1.8rem;
  padding: 1.5rem 0;
  flex-basis: 100%;
  outline: none;
  transition: all 300ms ease-in-out;
  margin-bottom: 2rem;
}

@media (min-width: 1024px) {
  .lead-form input:not([type='button']),
  .lead-form select,
  .lead-form textarea {
    flex-basis: 48%;
  }
}

.lead-form input:not([type='button'])::placeholder,
.lead-form select::placeholder,
.lead-form textarea::placeholder {
  color: #fffffe;
  opacity: 0.6;
}

.lead-form input:not([type='button']):hover, .lead-form input:not([type='button']):focus,
.lead-form select:hover,
.lead-form select:focus,
.lead-form textarea:hover,
.lead-form textarea:focus {
  background: transparent;
}

.lead-form input:not([type='button']):focus,
.lead-form select:focus,
.lead-form textarea:focus {
  padding-left: 2rem;
  background: rgba(255, 255, 255, 0.07);
  border-color: #ffeb5c;
}

.lead-form textarea {
  min-height: 25rem;
  width: 100%;
  flex-basis: 100%;
  resize: none;
}

.lead-form input[type='submit'],
.lead-form input[type='button'],
.lead-form .button {
  display: block;
  transition: all 0.3s ease-in-out;
  border-radius: 4rem;
  border: 0.3rem solid;
  border-color: inherit;
  font-size: 1.6rem;
  font-weight: normal;
  font-style: normal;
  padding: 0.8rem 3rem;
  height: auto;
  color: #ffeb5c;
  background: none;
  cursor: pointer;
}

.lead-form input[type='submit']:hover, .lead-form input[type='submit']:focus,
.lead-form input[type='button']:hover,
.lead-form input[type='button']:focus,
.lead-form .button:hover,
.lead-form .button:focus {
  background: #ffeb5c;
  border-color: #ffeb5c;
}

.lead-form select,
.lead-form select:hover,
.lead-form select:focus,
.lead-form select:active {
  background: #1c1b21;
  border-bottom: 0.5rem solid white;
  transition: all 300ms ease-in-out;
}

.lead-form input[type='file'] {
  background: 0;
  border-bottom: 0.5rem solid white;
  width: 100%;
  text-align: left;
  padding-left: 0;
  padding-top: 15px;
  padding-bottom: 15px;
  cursor: pointer;
  outline: none;
  color: rgba(255, 255, 254, 0.6);
}

.lead-form input[type='file']:hover, .lead-form input[type='file']:focus {
  color: #fff;
  border-color: #ffeb5c;
}

.lead-form .file-input {
  margin-bottom: 2rem;
}

@media (min-width: 1024px) {
  .lead-form .file-input {
    flex-basis: 48%;
  }
}

.lead-form .file-input input {
  margin-bottom: 0;
}

.lead-form .file-input label[for='file'] {
  display: block;
  font-size: 1rem;
  opacity: 0.8;
}

.lead-form input[type='submit'] {
  border-color: #ffeb5c;
  color: #ffeb5c !important;
}

.lead-form input[type='submit']:hover, .lead-form input[type='submit']:focus {
  color: #1c1b21 !important;
  background: #ffeb5c;
}

/* Slider */
.slick-loading .slick-list {
  background: #fff url("./ajax-loader.gif") center center no-repeat;
}

/* Icons */
@font-face {
  font-family: "slick";
  src: url("./fonts/slick.eot");
  src: url("./fonts/slick.eot?#iefix") format("embedded-opentype"), url("./fonts/slick.woff") format("woff"), url("./fonts/slick.ttf") format("truetype"), url("./fonts/slick.svg#slick") format("svg");
  font-weight: normal;
  font-style: normal;
}

/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none;
}

.slick-prev:hover, .slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  outline: none;
  background: transparent;
  color: transparent;
}

.slick-prev:hover:before, .slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1;
}

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0.25;
}

.slick-prev:before,
.slick-next:before {
  font-family: "slick";
  font-size: 20px;
  line-height: 1;
  color: white;
  opacity: 0.75;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
  left: -25px;
}

[dir="rtl"] .slick-prev {
  left: auto;
  right: -25px;
}

.slick-prev:before {
  content: "←";
}

[dir="rtl"] .slick-prev:before {
  content: "→";
}

.slick-next {
  right: -25px;
}

[dir="rtl"] .slick-next {
  left: -25px;
  right: auto;
}

.slick-next:before {
  content: "→";
}

[dir="rtl"] .slick-next:before {
  content: "←";
}

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%;
}

.slick-dots li {
  position: relative;
  display: inline-block;
  height: 20px;
  width: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}

.slick-dots li button {
  border: 0;
  background: transparent;
  display: block;
  height: 20px;
  width: 20px;
  outline: none;
  line-height: 0px;
  font-size: 0px;
  color: transparent;
  padding: 5px;
  cursor: pointer;
}

.slick-dots li button:hover, .slick-dots li button:focus {
  outline: none;
}

.slick-dots li button:hover:before, .slick-dots li button:focus:before {
  opacity: 1;
}

.slick-dots li button:before {
  position: absolute;
  top: 0;
  left: 0;
  content: "•";
  width: 20px;
  height: 20px;
  font-family: "slick";
  font-size: 6px;
  line-height: 20px;
  text-align: center;
  color: black;
  opacity: 0.25;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-dots li.slick-active button:before {
  color: black;
  opacity: 0.75;
}

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}

[dir="rtl"] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}
