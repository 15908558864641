.imgal-container {
  line-height: 0;
  column-count: 1;
}

.imgal-img {
  width: 100%;
  height: auto;
  transition: filter 0.2s;
  margin-bottom: 1rem;
}

.imgal-img:hover {
  -webkit-filter: opacity(80%);
  filter: opacity(80%);
  cursor: pointer;
}

.imgal-modal {
  margin: auto;
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(black, 0.9);
  color: #fff;
  vertical-align: middle;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    max-height: 80%;
    max-width: 80%;
  }
}

#imgal-modal-close {
  display: inline;
  font-size: 4rem;
  position: absolute;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  top: 5rem;
  right: 5rem;
  cursor: pointer;
}

.imgal-modal-img {
  margin: auto;
  object-fit: contain;
}

@include bp(mobile-l) {
  .imgal-container {
    column-count: 2;
    column-gap: 1rem;
  }
}

@include bp(tablet) {
  .imgal-container {
    column-count: 3;
  }
}

@include bp(laptop) {
  .imgal-container {
    column-count: 4;
  }
}

@include bp(laptop-l) {
  .imgal-container {
    column-count: 5;
  }
}
