.site-header {
  background: rgba($base-color, 0.7);
  display: flex;
  align-items: stretch;
  height: 6.8rem;
  position: fixed;
  z-index: 999;
  top: 0;
  width: 100%;

  .site-logo {
    width: 8rem;
    background: $color-white;
    padding: 1rem 0.8rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__quote,
  &__search,
  &__phone {
    display: inline-block;
    max-width: 8rem;
    max-height: 8rem;
  }

  svg {
    fill: $color-yellow;
    padding: 2.3rem;
    width: 100%;
    height: 100%;
    display: block;
  }

  .menu-button,
  &__phone,
  &__quote {
    border-right: solid 0.1rem #2e2c34;
  }

  &__search {
    background: transparent;
    width: auto;
    margin-right: auto;
    border: 0;

    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@include bp(laptop) {
  .site-header {
    flex-direction: column;
    height: 100%;
    max-width: 8rem;
    left: 0;
    background: linear-gradient(
      to bottom,
      rgba($base-color, 1) 0%,
      rgba($base-color, 0) 100%
    );

    &.scroll {
      background: rgba($base-color, 0.7);
    }

    .menu-button,
    &__phone,
    &__quote {
      border-right: 0;
      border-bottom: solid 0.1rem #2e2c34;
    }

    &__phone,
    &__quote,
    &__search {
      width: 8rem;
      height: 8rem;
      display: flex;
      justify-content: center;
      align-items: center;
      outline: none;
      svg {
        width: 2.5rem;
        height: 2.5rem;
        display: block;
        margin: auto;
        padding: 0;
      }
    }

    .menu-button {
      padding: 2.5rem 0;
    }

    &__search {
      cursor: pointer;
    }
  }

  // &.scroll {
  //   height: auto;
  //   .site-header__quote,
  //   .site-header__phone {
  //     display: flex;
  //     justify-content: center;
  //     align-items: center;
  //   }
  // }
}

.admin-bar .site-header {
  top: 4.6rem;
  @media (min-width: 600px) {
    top: 3.2rem;
  }
}

@include bp(full) {
  .site-header {
    transition: $global-transition;
    // left: 5rem;
  }
}
