.search-modal {
  position: fixed;
  z-index: 900;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.85);
  opacity: 0;
  visibility: none;
  pointer-events: none;
  transition: $global-transition;

  &.is-active {
    opacity: 1;
    visibility: visible;
    pointer-events: all;
  }

  &__inner-container {
    width: 100%;
  }

  .search-modal-close {
    position: absolute;
    top: 10rem;
    right: 5rem;
  }

  input[type='text'] {
    padding: 1.15rem 2rem;
    border: 0;
    background: $color-white;
    color: #333;
    outline: 0;
    font-size: 1.8rem;
    border-radius: 4rem;
    flex: 1;
    transition: $global-transition;
    border: 0.2rem solid transparent;

    &:active,
    &:focus {
      border: 0.2rem solid $color-yellow;
    }
  }

  button {
    border: 0;
    background: transparent;
    svg {
      max-width: 2.5rem;
      max-height: 2.5rem;
      fill: $color-white;
    }
  }

  button[type='submit'] {
    background: $color-yellow;
    padding: 0.5rem 5rem;
    cursor: pointer;
    border: 0.2rem solid $color-yellow;
    border-radius: 4rem;
    width: 100%;
    margin-top: 0.5rem;
  }

  form {
    width: 100%;
    display: flex;
    flex-flow: row wrap;

    align-items: stretch;
    max-width: 80rem;
    margin: 0 auto;
  }
  label {
    font-size: 2.4rem;
    font-weight: 800;
    flex-basis: 100%;
    text-align: left;
    margin-bottom: 0.5rem;
  }
}

@include bp(mobile-l) {
  .search-modal {
    input[type='text'] {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      flex: 0 0 80%;
    }
    button[type='submit'] {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      padding-left: 4rem;
      padding-right: 4rem;
      width: auto;
      flex: 0 0 20%;
      margin: 0;
    }
  }
}
@include bp(tablet) {
  .search-modal {
    button[type='submit'] {
      padding-left: 4rem;
      padding-right: 4rem;
    }
  }
}
