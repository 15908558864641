.nav-collapsed {
  background: $base-color;
  position: fixed;
  z-index: 998;
  top: 0;
  width: 100%;
  left: -100%;
  padding: 6rem 7.5rem 0;
  transition: $global-transition;
  height: 100vh;
  display: flex;
  align-items: center;

  a {
    font-family: $font-subheader;
    font-size: 4.5rem;

    &:hover,
    &:focus {
      animation: neon 2.5s linear infinite;

      svg {
        animation: neon 2.5s linear infinite;
      }
    }
  }

  // TODO: This
  // .menu-item-has-children svg,
  // .menu-item-has-children .menu-item {
  //   display: none;
  // }

  .menu-item-has-children a {
    display: flex;
    align-items: center;

    .icon {
      max-width: 5rem;
      max-height: 5rem;
      margin-left: 3rem;
      position: relative;
      z-index: 5;
      fill: $color-yellow;
    }

    &:hover,
    &:focus {
      .icon {
        animation: move-right 1.5s linear infinite;
      }
    }
  }

  .menu-item-has-children.submenu-open {
    .nav-child {
      display: block;
    }
  }

  .menu-item-has-children .nav-child {
    display: none;
    li {
      list-style: none;
      a {
        font-size: 4.5rem;
      }
    }
  }

  .site-logo {
    margin: 4rem 0;
    opacity: 0.7;
    max-width: 32rem;
  }

  &.is-open {
    left: 0;
  }

  &.nav-has-background-image {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 20% 50%;
  }

  .social-media {
    opacity: 0.7;
    h4, .heading-size-4 {
      font-family: $font-body;
      font-weight: bold;
      font-size: 1.8rem;
    }
  }
}

@include bp(tablet) {
  .nav-collapsed {
    padding: 0 5rem 0 18vw;

    .wp-block-columns {
      max-width: 100rem;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      width: 100%;
    }

    .primary-nav .menu {
      a {
        font-size: 7vmin;
        line-height: 1.5;
      }
    }
    .site-logo {
      margin-left: auto;
      margin-bottom: 5rem;
    }
    .social-media {
      h4, .heading-size-4 {
        font-size: 2.7rem;
        text-align: right;
      }

      .social-media-menu {
        justify-content: flex-end;
        margin-top: 2rem;
      }
    }
  }
}

/*
   Social Media Nav
*/

.social-media-nav {
  .social-media-menu {
    display: flex;
  }

  li:not(:last-of-type) {
    margin-right: 1rem;
  }

  .social-media-menu__link {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 5rem;
    height: 5rem;
    border: 0.3rem solid $color-white;
    transition: $global-transition;
    border-radius: 50%;
    position: relative;
    opacity: 0.8;

    &::before {
      content: '';
      width: 4.2rem;
      height: 4.2rem;
      position: absolute;
      border-radius: 50%;
      border: 0.3rem solid $color-white;
    }

    svg {
      width: 100%;
      height: 100%;
      max-width: 2rem;
      max-height: 2rem;
      fill: $color-white;
    }

    &:hover,
    &:focus {
      opacity: 1;
    }
  }
}

.menu-button {
  font: inherit;
  display: inline-block;
  overflow: visible;
  margin: 0;
  padding: 0 3rem;
  cursor: pointer;
  transition-timing-function: linear;
  transition-duration: 0.15s;
  transition-property: opacity, filter, background;
  text-transform: none;
  color: inherit;
  border: 0;
  background-color: transparent;
  z-index: 100;

  &:focus {
    outline: none;
  }

  &--box {
    position: relative;
    display: inline-block;
    width: 2rem;
    height: 1.5rem;
  }

  &--inner::before,
  &--inner::after {
    display: block;
    content: '';
  }

  &--inner,
  &--inner::before,
  &--inner::after {
    position: absolute;
    left: 0;
    width: 2.6rem;
    height: 0.3rem;
    transition-timing-function: ease;
    transition-duration: 0.15s;
    transition-property: transform;
    background-color: $color-yellow;
  }

  &--inner {
    transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition-duration: 75ms;
    top: 50%;
    display: block;
  }

  &--inner::before {
    transition: top 75ms ease 0.12s, opacity 75ms ease;
    top: -0.75rem;
  }

  &--inner::after {
    transition: top 75ms ease 0.12s, opacity 75ms ease;
    top: 0.75rem;
  }
}

.menu-open {
  // Button
  background: $color-yellow;

  .menu-button--inner,
  .menu-button--inner::before,
  .menu-button--inner::after {
    background: $base-color;
  }

  .menu-button--inner {
    transition-delay: 0.12s;
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: rotate(45deg);

    &::before,
    &::after {
      top: 0;
      transition: top 75ms ease, transform ease 0.15s;
      transition-delay: 0.12s;
      transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
      transform: rotate(90deg);
    }
  }
}
