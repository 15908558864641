/* ==========================================================================
   #HEADINGS
   ========================================================================== */

/**
 * Simple default styles for headings 1 through 6. Anything more opinionated
 * than simple font-size changes should likely be applied via classes (see:
 * http://csswizardry.com/2016/02/managing-typography-on-large-apps/).
 */

h1, h2, h3, h4, .heading-size-1, .heading-size-2, .heading-size-3, .heading-size-4 {
  line-height:1.1;
  margin: 1.2rem 0;
  color: $comp-color;

  & + p {
    margin-top: 2.4rem;
  }
}

h1, h2, .heading-size-1, .heading-size-2 {
  font-family: $font-header;
  font-weight: 800;
  text-transform: uppercase;
  font-size: 4rem;
  word-break: normal;
  word-wrap: normal;
  overflow-wrap: normal;
}

body h1, body .heading-size-1 {
  @include bp(tablet) {
    font-size: 4rem;
  }

  @include bp(laptop-l) {
    font-size: 8rem;
  }
}

h2, .heading-size-2 {

  @include bp(desktop) {
    font-size: 7rem;
  }
}

h3, .heading-size-3 {
  font-size: 2.7rem;
  font-weight: normal;
  font-family: $font-subheader;
  text-transform: uppercase;

  @include bp(desktop) {
    font-size: 4rem;
  }
}

h4, .heading-size-4 {
  font-size: 2rem;
  font-weight: 400;
  font-family: $font-subheader;
  text-transform: uppercase;
  margin-bottom: 1rem;

  @include bp(desktop) {
    font-size: 2.4rem;
  }
}


