.has-text-color-yellow {
  color: $color-yellow;
}

.flicker {
  &-white {
    animation: text-flicker-white 5s linear infinite;
    .offset {
      animation: letter-flicker 4s linear infinite;
    }
  }

  &-yellow {
    animation: text-flicker-yellow 5s linear infinite;
  }
}

@keyframes text-flicker-yellow {
  0% {
    opacity: 0.1;
    text-shadow: 0px 0px 20px rgba($color-yellow, 1);
  }

  2% {
    opacity: 1;
    text-shadow: 0px 0px 20px rgba($color-yellow, 1);
  }
  8% {
    opacity: 0.1;
    text-shadow: 0px 0px 20px rgba($color-yellow, 1);
  }
  9% {
    opacity: 1;
    text-shadow: 0px 0px 20px rgba($color-yellow, 1);
  }
  12% {
    opacity: 0.1;
    text-shadow: 0px 0px rgba($color-yellow, 1);
  }
  20% {
    opacity: 1;
    text-shadow: 0px 0px 20px rgba($color-yellow, 1);
  }
  25% {
    opacity: 0.3;
    text-shadow: 0px 0px 20px rgba($color-yellow, 1);
  }
  30% {
    opacity: 1;
    text-shadow: 0px 0px 20px rgba($color-yellow, 1);
  }

  70% {
    opacity: 0.7;
    text-shadow: 0px 0px 20px rgba($color-yellow, 1);
  }

  72% {
    opacity: 0.2;
    text-shadow: 0px 0px 20px rgba($color-yellow, 1);
  }

  77% {
    opacity: 0.9;
    text-shadow: 0px 0px 20px rgba($color-yellow, 1);
  }
  100% {
    opacity: 0.9;
    text-shadow: 0px 0px 20px rgba($color-yellow, 1);
  }
}
@keyframes text-flicker-white {
  0% {
    opacity: 0.1;
    text-shadow: 0px 0px 20px rgba($color-white, 1);
  }

  2% {
    opacity: 1;
    text-shadow: 0px 0px 20px rgba($color-white, 1);
  }
  8% {
    opacity: 0.1;
    text-shadow: 0px 0px 20px rgba($color-white, 1);
  }
  9% {
    opacity: 1;
    text-shadow: 0px 0px 20px rgba($color-white, 1);
  }
  12% {
    opacity: 0.1;
    text-shadow: 0px 0px rgba($color-white, 1);
  }
  20% {
    opacity: 1;
    text-shadow: 0px 0px 20px rgba($color-white, 1);
  }
  25% {
    opacity: 0.3;
    text-shadow: 0px 0px 20px rgba($color-white, 1);
  }
  30% {
    opacity: 1;
    text-shadow: 0px 0px 20px rgba($color-white, 1);
  }

  70% {
    opacity: 0.7;
    text-shadow: 0px 0px 20px rgba($color-white, 1);
  }

  72% {
    opacity: 0.2;
    text-shadow: 0px 0px 20px rgba($color-white, 1);
  }

  77% {
    opacity: 0.9;
    text-shadow: 0px 0px 20px rgba($color-white, 1);
  }
  100% {
    opacity: 0.9;
    text-shadow: 0px 0px 20px rgba($color-white, 1);
  }
}
@keyframes letter-flicker {
  0% {
    opacity: 0.1;
    text-shadow: 0px 0px 20px rgba($color-white, 1);
  }
  2% {
    opacity: 0.1;
    text-shadow: 0px 0px 20px rgba($color-white, 1);
  }
  4% {
    opacity: 1;
    text-shadow: 0px 0px 20px rgba($color-white, 1);
  }

  19% {
    opacity: 1;
    text-shadow: 0px 0px 20px rgba($color-white, 1);
  }
  21% {
    opacity: 0.1;
    text-shadow: 0px 0px 20px rgba($color-white, 1);
  }
  23% {
    opacity: 1;
    text-shadow: 0px 0px 20px rgba($color-white, 1);
  }

  80% {
    opacity: 1;
    text-shadow: 0px 0px 20px rgba($color-white, 1);
  }
  83% {
    opacity: 0.4;
    text-shadow: 0px 0px 20px rgba($color-white, 1);
  }
  87% {
    opacity: 1;
    text-shadow: 0px 0px 20px rgba($color-white, 1);
  }
}

@keyframes neon {
  0%,
  100% {
    text-shadow: 0 0 1vw #fa1c16, 0 0 3vw #fa1c16, 0 0 10vw #fa1c16,
      0 0 10vw #fa1c16, 0 0 0.4vw #fed128, 0.5vw 0.5vw 0.1vw #806914;
    color: #fed128;
  }
  50% {
    text-shadow: 0 0 0.5vw #800e0b, 0 0 1.5vw #800e0b, 0 0 5vw #800e0b,
      0 0 5vw #800e0b, 0 0 0.2vw #800e0b, 0.5vw 0.5vw 0.1vw #40340a;
    color: #806914;
  }
}

@keyframes move-right {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(1rem);
  }
  100% {
    transform: translateX(0);
  }
}

.neon {
  animation: neon 2.5s linear infinite;
}

.wp-block-group,
.wysiwyg {
  h2, .heading-size-2,
  h3, .heading-size-3,
  h4, .heading-size-4,
  h5, .heading-size-5,
  h6 .heading-size-6 {
    font-family: $font-body;
    font-weight: 800;
    margin: 3rem 0 1rem 0;
    color: $color-yellow;
  }

  p {
    margin: 0 0 2rem 0;
  }

  h2, .heading-size-2 {
    font-size: 4rem;
  }
  h3, .heading-size-3 {
    font-size: 3.5rem;
  }
  h4, .heading-size-4 {
    font-size: 3rem;
  }
  h5, .heading-size-5 {
    font-size: 2.5rem;
  }
  h6, .heading-size-6 {
    font-size: 2rem;
  }
  ol,
  ul {
    padding: 0 2rem;
    margin-bottom: 2rem;
    li {
      margin-bottom: 0.25rem;
    }
    li::marker {
      color: $color-yellow;
    }
  }
}
