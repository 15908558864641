/* ==========================================================================
   #CONTAINER
   ========================================================================== */
.site,
body {
  background-color: $base-color;
}

/**
 * Page-level constraining and wrapping elements.
 */

.wp-block-group,
.container {
  margin-right: auto;
  margin-left: auto;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  max-width: 1760px;
  &.small {
    max-width: 130rem;
  }
}

@include bp(laptop) {
  .wp-block-group,
  .container {
    padding-left: 9%;
    padding-right: 9%;
  }
}
@include bp(full) {
  .wp-block-group,
  .container {
    padding-left: 10rem;
    padding-right: 10rem;
    
  }
}
.wp-block-image img {
  height: auto;
}
// @media (min-width: 1760px) {
//   .container {
//     padding-left: 0;
//     padding-right: 0;
//   }
// }

/* ==========================================================================
   #Info block
   ========================================================================== */

.wp-block-columns.info-blocks {
  .wp-block-column {
    position: relative;
    overflow: hidden;
    padding: 6rem 1.5rem;
    z-index: 1;
    text-align: right;
    margin: 0;

    .number {
      font-size: 5.3rem;
      display: block;
      width: 100%;
      font-family: $font-body;
      font-weight: 800;
    }

    .description {
      font-size: 1.6rem;
      color: $color-yellow;
      font-weight: 800;
      font-family: $font-body;
    }

    img {
      position: absolute;
      z-index: -1;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      opacity: 0.65;
      min-height: 100%;
      max-width: none;
    }
  }
}
@media (min-width: 600px) {
  .wp-block-columns.info-blocks {
    flex-wrap: nowrap !important;
  }
}

@include bp(laptop) {
  .wp-block-columns.info-blocks {
    .wp-block-column {
      padding: 12rem 2.5rem;

      img {
        min-width: 100%;
      }
    }
  }
}

.wp-block-group .wp-block-columns .wp-block-column {
  h3, .heading-size-3 {
    color: $color-yellow;
    font-family: $font-body;
    font-size: 2.7rem;
    font-weight: bold;
  }
}
/* ==========================================================================
   #Cover Block
========================================================================== */

.wp-block-cover {
  height: auto !important;
  &::after,
  &::before {
    content: '';
    pointer-events: none;
    background-color: transparent !important;
    height: 50%;
  }
  &::before {
    background: linear-gradient(
      to bottom,
      rgba($base-color, 1) 0%,
      rgba($base-color, 0) 25%
    );
  }

  &::after {
    background: linear-gradient(
      0deg,
      rgba($base-color, 1) 0%,
      rgba($base-color, 0) 80%
    );
    bottom: 0;
    top: auto;
  }
  .wp-block-cover {
    &__inner-container {
      display: block;
      padding-top: 5rem;
      padding-bottom: 5rem;
      @include bp(laptop) {
        padding-top: 10rem;
        padding-bottom: 10rem;
      }
    }
  }
}
/* ==========================================================================
   # Media Text
========================================================================== */
.wp-block-media-text {
  display: block !important;

  & &__media {
    position: relative;
    &::before,
    &::after {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      right: 0;
      z-index: 2;
      bottom: 0;
      height: 100%;
    }

    &::before {
      background: linear-gradient(
        to bottom,
        rgba(28, 27, 33, 1) 5%,
        rgba(28, 27, 33, 0) 50%,
        rgba(28, 27, 33, 1) 95%
      ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    }

    &::after {
      background: linear-gradient(
        to left,
        rgba(28, 27, 33, 1) 5%,
        rgba(28, 27, 33, 0) 50%,
        rgba(28, 27, 33, 1) 100%
      );
    }
  }
  &.is-stacked-on-mobile .wp-block-media-text__content,
  & .wp-block-media-text__content {
    padding: 0 $page-padding;
  }
}

@include bp(laptop) {
  .wp-block-media-text {
    display: grid !important;

    & &__content {
      max-width: 87.5rem;
      padding-top: 5rem !important;
      padding-bottom: 5rem !important;
    }
    & &__media {
      overflow: hidden;
      position: relative;
      align-self: stretch !important;

      img {
        min-height: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        max-width: none;
        width: auto;
      }
    }
  }
}
/* ==========================================================================
   # Group Block
========================================================================== */
.page-content .wp-block-group {
  max-width: 1760px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10rem;

  .wp-block-columns {
    padding-left: 0;
  }

  @include bp(full) {
    padding-left: 10rem;
  }
}
/* ==========================================================================
   # Cover
========================================================================== */
.wp-block-cover {
  margin: 5rem auto;
}
/* ==========================================================================
   # Columns
========================================================================== */
.page-content .wp-block-columns {
  max-width: 1760px;
  margin-left: auto;
  margin-right: auto;
  @include bp(laptop) {
    padding-left: 10rem;
  }
}
