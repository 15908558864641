// Gallery

.flexible-gallery {
  margin: 1.5rem;

  img {
    transition: $global-transition;
    cursor: pointer;
    opacity: 0.5;

    width: 100%;
    margin-bottom: 1.5rem;

    &:hover,
    &:focus {
      opacity: 1;
    }
  }
}
@include bp(tablet) {
  .flexible-gallery {
    column-count: 2;
    column-gap: 1.5rem;
  }
}
@include bp(laptop) {
  .flexible-gallery {
    column-count: 3;
  }
}
// Text
.flexible-text {
  h2, .heading-size-2,
  h1, .heading-size-1 {
    color: $color-yellow;
  }

  ol,
  ul {
    padding: 0 2rem;
    margin-bottom: 2rem;
    li {
      margin-bottom: 0.25rem;
    }
    li::marker {
      color: $color-yellow;
    }
  }
}

// Media Text
.flexible-media-text {
  margin-bottom: 15rem;

  h2, .heading-size-2 {
    font-size: 8vmin;
    color: $color-yellow;
    margin-bottom: 2rem;
  }

  h3, .heading-size-3 {
    font-size: 2.7rem;
    font-family: $font-header;
    font-weight: 700;
    color: $color-yellow;
    margin: 6rem auto 4rem auto;
  }
}

@include bp(laptop) {
  .flexible-media-text {
    .wp-block-media-text {
      h2, .heading-size-2 {
        font-size: 8rem;
        text-transform: initial;
      }
      .wp-block-media-text__content {
        padding-left: 9rem;
        margin-left: auto;
      }
    }
  }
}

// Form
.flexible-form {
  &__blurb {
    font-size: 2.1rem;
  }

  .container {
    max-width: 115rem;
  }

  .nf-form-cont {
    max-width: 78rem;
    margin: 5rem auto;
  }

  h2, .heading-size-2{
    font-size: 8vmin;
  }

  h3, .heading-size-3 {
    font-size: 2.2rem;
  }
}
