///* ========================================================================
//   #GLOBAL
//   ======================================================================== */
// The global settings file contains any project-wide variables; things that
// need to be made available to the entire codebase.

$global-transition: all 300ms ease-in-out !default;
$border-radius: 5px;
$page-padding: 1.5rem;
/* Fonts
   ========================================================================== */

$font-body: 'Open Sans', sans-serif;
$font-header: 'Open Sans', sans-serif;
$font-subheader: 'mexcellent', sans-serif;

$page-padding: 1.5rem;
/* Breakpoints
   ========================================================================== */

$breakpoints: (
  mobile-m: 375px,
  mobile-l: 500px,
  tablet: 768px,
  laptop: 1024px,
  laptop-l: 1440px,
  desktop: 1600px,
  full: 1920px,
);

/* Colors
   ========================================================================== */
$base-color: #1c1b21;
$comp-color: #fff;

$color-white: #fff;

$color-grey-dark: #2c2b33;
$color-grey-medium: #333333;
$color-grey-light: #a6a5af;

$color-red: #fd3a3a;
$link-color: #ffeb5c;
$link-color--hover: darken($link-color, 5%);
$color-yellow--darker: #f5e158;
$color-yellow: #ffeb5c;

/* Colors Social
   ========================================================================== */

$colors: (
  behance: #1769ff,
  codepen: #0ebeff,
  deviantart: #05cc47,
  digg: #005be2,
  docker: #0db7ed,
  dribbble: #ea4c89,
  dropbox: #007ee5,
  facebook: #3b5998,
  flickr: #0063dc,
  foursquare: #f94877,
  google: #dd4b39,
  github: #4078c0,
  instagram: #e1306c,
  linkedin: #0077b5,
  pinterest: #bd081c,
  periscope: #3aa4c6,
  pocket: #ef4056,
  reddit: #ff4500,
  skype: #00aff0,
  slideshare: #0077b5,
  snapchat: #fffc00,
  soundcloud: #f80,
  spotify: #1db954,
  stumbleupon: #eb4924,
  tumblr: #35465c,
  twitch: #6441a5,
  twitter: #1da1f2,
  vimeo: #1ab7ea,
  vine: #00b488,
  vk: #45668e,
  wp: #21759b,
  yelp: #af0606,
  youtube: #f00,
);
